<template>
  <div class="div-form-main order-form" v-loading="isOrderFormLoading || isDeliveredProductTableLoading">
    <div>
      <div v-if="!isOnline">
        <h1>Order Confirmation</h1>
      </div>
      <div  v-bind:class="{'flex-left-column': isMobileScreen,'flex-between-row':!isMobileScreen}">
        <div class="div-form-tab" >
          <el-tabs v-if="!isOps && orderForm.orderType === ORDER_TYPE.DELIVERED && (isOnline || (!isOnline && tradeType === ORDER_TRADE_TYPE.PURCHASE))" v-model="orderFormTabActive">
            <el-tab-pane label="Order Information" name="ORDER_INFO" :disabled="currentView === ORDER_VIEW.EDIT_ORDER_COMM"/>
            <el-tab-pane v-if="! isVictoryFeatsCusPO" label="Commission" name="COMMISSION" :disabled="tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES || (tradeType === ORDER_TRADE_TYPE.SALES && !orderForm.sellerOrderStatus)
              || (tradeType === ORDER_TRADE_TYPE.PURCHASE && !orderForm.buyerOrderStatus) || (tradeType === ORDER_TRADE_TYPE.PURCHASE && this.orderForm.offlineBuyerSeller === 'SELLER') || currentView === ORDER_VIEW.EDIT_ORDER || isEditPurchaseOrder"/>
          </el-tabs>
        </div>
        <div class="div-offline-form-btn" v-if="!isOnline && ((tradeType  === ORDER_TRADE_TYPE.SALES && orderForm.sellerOrderStatus === ORDER_STATUS.PENDING_SELLER_CONFIRMATION) || (tradeType  === ORDER_TRADE_TYPE.PURCHASE && orderForm.buyerOrderStatus === ORDER_STATUS.PENDING_BUYER_CONFIRMATION))">
          <el-button round type="primary"  @click="handleConfirmOrder()" :size="isMobileScreen ? 'mini' : 'small'" >Confirm Order</el-button>
        </div>
        <div :class="(currentView === ORDER_VIEW.CREATE_ORDER || !isOrderSideBar) ? 'div-form-main-btn div-create-btn' : 'div-form-main-btn div-edit-btn'" v-if="!isOrderFormLoading && isOnline">
          <el-button round type="primary" :size="isMobileScreen ? 'mini' : 'small'"  @click="orderFormTabActive === 'ORDER_INFO' ? handleOrderViewChange('EDIT_ORDER') : handleOrderViewChange('EDIT_ORDER_COMM')"
            v-if="hasEditButton()"
            :disabled="(orderForm.buyerOrderStatus === ORDER_STATUS.CONFIRMED && orderForm.sellerOrderStatus === ORDER_STATUS.PENDING_CREDIT_AUTHORIZATION) || (tradeType === ORDER_TRADE_TYPE.PURCHASE && orderFormTabActive === 'COMMISSION')"
          >
            Edit
          </el-button>
          <el-button round type="danger" :size="isMobileScreen ? 'mini' : 'small'"  @click="handleSubmitOrder(true)"
            v-if="currentView === ORDER_VIEW.CREATE_ORDER || (currentView === ORDER_VIEW.EDIT_ORDER && (tradeType === ORDER_TRADE_TYPE.SALES ? orderForm.sellerOrderStatus === ORDER_STATUS.DRAFT : orderForm.buyerOrderStatus === ORDER_STATUS.DRAFT))"
          >
            Save as Draft
          </el-button>
          <el-button round type="primary" :size="isMobileScreen ? 'mini' : 'small'" @click="handleSubmitOrder(false)"
            v-if="currentView === ORDER_VIEW.CREATE_ORDER || currentView === ORDER_VIEW.EDIT_ORDER || isEditPurchaseOrder"
          >
            Submit
          </el-button>
          <el-button round type="primary" :size="isMobileScreen ? 'mini' : 'small'"  v-if="currentView === ORDER_VIEW.EDIT_ORDER_COMM" @click="submitOrderComm">
            Submit
          </el-button>
          <el-button v-if="currentView === ORDER_VIEW.EDIT_ORDER || currentView === ORDER_VIEW.EDIT_ORDER_COMM || isEditPurchaseOrder"
            round  type="primary" :size="isMobileScreen ? 'mini' : 'small'"  @click="handleCancelEditClick"
          >
            Cancel Edit
          </el-button>
          <el-button
            v-if="!isEditPurchaseOrder && !isOps && !isViewOnly && currentView !== ORDER_VIEW.EDIT_ORDER && currentView !== ORDER_VIEW.EDIT_ORDER_COMM"
            round  outline :size="isMobileScreen ? 'mini' : 'small'"  @click="handleCloseOrderForm('CLOSE')"
          >
            Close
          </el-button>
          <!-- Only Ex-Wharf cannot view PDF -->
          <el-dropdown trigger="click"
            v-if="!isViewOnly && !isOps && currentView !== ORDER_VIEW.CREATE_ORDER && (
                hasConfirmDropdownItem() || hasCancelDropdownItem() || hasDeleteDropdownItem() || hasVerifyDropdownItem()
              )"
            @command="handleOrderFormDropdownClick"
          >
            <el-button class="el-dropdown-more-btn" :size="isMobileScreen ? 'mini' : 'small'" round>
              <em class="el-icon-more"></em>
            </el-button>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item
                v-if="hasConfirmDropdownItem()"
                command="CONFIRM"
              >
                Confirm Order
              </el-dropdown-item>
              <el-dropdown-item
                v-if="hasCancelDropdownItem()"
                command="CANCEL"
              >
                Cancel Order
              </el-dropdown-item>
              <el-dropdown-item
                v-if="hasDeleteDropdownItem()"
                command="DELETE"
              >
                Delete Order
              </el-dropdown-item>
              <el-dropdown-item
                v-if="hasVerifyDropdownItem()"
                command="VERIFY"
              >
                Verify Order
              </el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </div>
    </div>
    <div :class="(currentView === ORDER_VIEW.CREATE_ORDER || !isOrderSideBar) ? 'div-form-main-container div-create-container' : 'div-form-main-container'" v-if="!isOrderFormLoading">
      <el-form v-if="orderFormTabActive === 'ORDER_INFO'" :model="orderForm" ref="orderForm" :validate-on-rule-change="false" label-position='top'>
        <div v-if="isOrderTopBar">
          <div style="border: 1px solid #E4E4E4;">
            <div class="div-top-summary-order-no">
              <h3>{{tradeType === ORDER_TRADE_TYPE.SALES ? 'Sales Order No:' : 'Purchase Order No:'}}</h3>
              <h3>{{tradeType === ORDER_TRADE_TYPE.SALES ? orderForm.salesOrderNo : orderForm.purchaseOrderNo}}</h3>
            </div>
            <div class="div-top-summary-order-status">
              <h3>Order Status:</h3>
              <div class="status-tag"  :style="getStatusTagStyle(orderForm.sellerSysOrganizationId === myCompany.id ? orderForm.sellerOrderStatus : orderForm.buyerOrderStatus)">{{orderForm.sellerSysOrganizationId === myCompany.id ? capitalizeFirstLetter(orderForm.sellerOrderStatus, '_') : capitalizeFirstLetter(orderForm.buyerOrderStatus, '_')}}</div>
            </div>
          </div>
        </div>
        <div class="basic-card-block margin-top-0" v-if="!isOps && currentView !== ORDER_VIEW.CREATE_ORDER">
          <h1 >Delivered Information</h1>
          <div>
            <el-table :data="deliveredProductTableData" border>
              <el-table-column label="Product Name" prop="productName" show-overflow-tooltip min-width="200px"/>
              <el-table-column label="Product Specs" prop="specificationName" show-overflow-tooltip min-width="200px"/>
              <el-table-column label="Barge Name" prop="bargeName" show-overflow-tooltip min-width="200px"/>
              <el-table-column v-if="orderForm.orderType !== ORDER_TYPE.EX_WHARF" label="BDN Document" min-width="200px">
                <template v-slot="scope">
                  <el-button v-if="scope.row.docNumber" type="primary" plain @click="previewFile(scope.row.bdnPdfId)">{{ scope.row.docNumber }}</el-button>
                  <p v-else>-</p>
                </template>
              </el-table-column>
              <el-table-column v-else label="Loading Documents" width="200">
                <template v-slot="scope">
                  <div>
                    <el-button v-if="scope.row.cqPdfId" type="primary" plain @click="previewFile(scope.row.cqPdfId)">{{`[${scope.row.docNumber}] CQ File`}}</el-button>
                    <el-button v-if="scope.row.coqPdfId" type="primary" plain @click="previewFile(scope.row.coqPdfId)" style="margin: 0">{{`[${scope.row.docNumber}] COQ File`}}</el-button>
                    <p v-if="!scope.row.cqPdfId && !scope.row.coqPdfId">-</p>
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="eBDN Date" show-overflow-tooltip min-width="200px">
                <template v-slot="scope">
                  {{ scope.row.docDate ? $moment(scope.row.docDate).format('DD-MM-YYYY') : '-' }}
                </template>
              </el-table-column>
              <el-table-column label="Delivered Quantity" show-overflow-tooltip min-width="200px">
                <template v-slot="scope">
                  {{ scope.row.docQty ? numberWithCommas(scope.row.docQty, 3) : '-' }}
                </template>
              </el-table-column>
              <el-table-column label="ETC" show-overflow-tooltip min-width="200px">
                <template v-slot="scope">
                  {{scope.row.etc ? $moment(scope.row.etc).format('DD-MM-YYYY') : '-'}}
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <template v-if="isShowVictoryFeats">
          <div class="basic-card-block margin-top-0" >
            <div class="flex-between-row"  style="display: flex;  flex-direction:row; width: 400px;">
              <div class="flex-between-column">
                <div class="sub-title-text" style=" text-align: left; font-weight: 400; line-height: 32px;">Credit Limit </div>
                <div class="sub-title-text" style=" text-align: left; font-size: 20px; font-weight: bolder; margin-top: 4px"><strong>SGD {{creditLimit??'-'}}</strong></div>

              </div>
              <div class="flex-between-column">
                <div class="sub-title-text pricing-date-picker" style="text-align: left; font-weight: 400;">
                  <el-form-item >

                    <el-date-picker
                     :disabled="!canEditVictoryFields"
                      placeholder="Pick Your Pricing Date"
                      type="date" format="dd-MM-yyyy"
                      v-model="orderForm.pricingDate"
                      @change="chooseDate($event)"
                    />

                    </el-form-item>
                </div>
                <div class="sub-title-text" style="text-align: left; font-size: 20px; font-weight: bolder; margin-top: -10px;padding-left: 10px;"><strong>SGD {{todayPricing ??'-'}}</strong></div>
              </div>
            </div>
            <h1>Summary Form</h1>
            <div>
              <div v-bind:class="{'div-frac-13-avg':!isMobileScreen,'div-frac-1':isMobileScreen}">
                <template v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE">
                  <el-form-item v-if="isOnline" label="Company Name (Seller)" prop="sellerSysOrganizationId" :rules="rules.reqRule">
                    <el-select
                      v-model="orderForm.sellerSysOrganizationId" filterable value-key="id"
                      @change="handleCompanyNameSelect($event, 'SELLER',true)"
                      :disabled="currentView === ORDER_VIEW.VIEW_ORDER || currentView === ORDER_VIEW.EDIT_ORDER || linkedPurchaseTermContract !== null"
                    >
                      <el-option
                        v-for="item in creditAssociationList"
                        :key="item.id" :label="item.counterpartyCompanyName" :value="item.counterpartySysOrganizationId"
                        :disabled="item.status !== 'ACTIVE'"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item v-else label="Company Name (Seller)">
                    <el-input v-model="orderForm.sellerCompanyName" disabled/>
                  </el-form-item>
                </template>
                <template v-else>
                  <el-form-item v-if="isOnline" label="Company Name (Buyer)" prop="buyerSysOrganizationId" :rules="rules.reqRule">
                    <el-select v-model="orderForm.buyerCompanyName" filterable value-key="id"
                      @change="handleCompanyNameSelect($event, 'BUYER',true)"
                      :disabled="currentView === ORDER_VIEW.VIEW_ORDER || currentView === ORDER_VIEW.EDIT_ORDER || linkedSalesTermContract !== null"
                    >
                      <el-option
                        v-for="item in creditAssociationList"
                        :key="item.id" :label="item.counterpartyCompanyName" :value="item.counterpartySysOrganizationId"
                        :disabled="item.status !== 'ACTIVE'"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item v-else label="Company Name (Buyer)">
                    <el-input v-model="orderForm.buyerCompanyName" disabled/>
                  </el-form-item>
                </template>

                <el-form-item label="Territory" prop="territory"  :rules="rules.reqRule">
                  <el-select v-model="orderForm.territory" filterable value-key="id"
                    :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                    @change="clearLocationAndBunkerBarge"
                  >
                    <el-option
                      v-for="item in [
                      {label:'Self-Collect at Kiosk',value:1},
                      {label:'Bunker Delivery Service',value:2},
                      ]"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"

                    />
                  </el-select>
                </el-form-item>
                <el-form-item label="Order Date" prop="orderDate"  :rules="rules.reqRule">
                  <el-date-picker :disabled="!canEditVictoryFields"
                    v-if="isVictoryFeatsCusPO"
                    type="datetime" :format="DATETIME_FORMAT.GEO_DATETIME"
                    v-model="orderForm.orderDate"
                    @change="(val) => {
                      orderForm.nominationDate = null;
                      handleChangeOrderDateViaSummary(val)
                    }"
                  />
                  <el-date-picker
                    v-else
                    type="datetime" :format="DATETIME_FORMAT.GEO_DATETIME"
                    :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                    v-model="orderForm.orderDate"
                    @change="orderForm.nominationDate = null"
                  />
                </el-form-item>
                <el-form-item label="Vessel" v-if="!isVesselTBC && !isVictoryFeatsCusPO" >
                  <el-select
                    :filter-method="(val)=>handleSearchMpaReceivingVessels(val)"
                    filterable
                    popper-class="custom-ship-selector"
                    v-model="vesselSearchKey"
                    style="width:100%;"
                    :disabled="!canEditVictoryFields"
                    @visible-change="searchVesselvisibleChange($event)"
                    @change="handleSelectVessel($event)"
                    placeholder="Select">
                    <span style="display: inline-block; width: 200px; font-size: 14px; font-weight: 500; margin-left: 20px; margin-bottom: 10px;">Name</span>
                    <span style="display: inline-block; width: 100px; font-size: 14px; font-weight: 500;">IMO</span>
                    <span style="display: inline-block; font-size: 14px; font-weight: 500;">Licence</span>
                    <el-option
                      v-for="item in searchedVesselList||[]"
                      :key="item.id"
                      :label="item.shipName"
                      :value="item.id">
                        <span style="display: inline-block; width: 200px; font-size: 12px;">{{item.shipName}} </span>
                        <span style="display: inline-block; width: 100px; font-size: 12px;">{{item.imo}}</span>
                        <span style="display: inline-block; font-size: 12px;">{{item.licenceNo}}</span>
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item  label="Bunker Location" prop="locationLevel" :rules="rules.reqRule">
                  <el-select :disabled="!canEditVictoryFields"
                    v-if="isVictoryFeatsSelfCollectKiosk" v-model="orderForm.bunkerLocation" @visible-change="handleLocationOption" @change="handleChangeCollectKioskLocation" >
                    <el-option
                      v-for="item in selfCollectKioskLocationOptions"
                      :key="item.value"
                      :label="item.label"
                      :value="item.value"
                    />
                  </el-select>
                  <el-cascader :disabled="!canEditVictoryFields" v-else clearable popper-class="el-cascader-long" style="width: 100%"
                    v-model="orderForm.locationLevel" :options="locationOptions" :props="locationOptionProps" @visible-change="handleLocationOption" >
                  </el-cascader>
                </el-form-item>
                <el-form-item label="Bunker Barge" prop="bunkerBargeName" :rules="rules.reqRule">
                  <el-input v-if="isVictoryFeatsSelfCollectKiosk" v-model="orderForm.bunkerBargeName" :disabled="true"/>
                  <barge-or-vessel-selector v-else :schedulFormData="orderForm" :availableBargesLoading="availableBargesLoading" :availableBargesOptions="availableBargesOptions" :disabled="!canEditVictoryFields"
                    @handleChangeBarge="handleChangeBarge" @visibleChange="visibleChange" />
                </el-form-item>
                <el-form-item label="Next Port" prop="nextPort" :rules="rules.reqRule">
                  <el-input v-model="orderForm.nextPort"  :disabled=" nextPortDisabled || !canEditVictoryFields" @input="handleInputNextPort"/>
                </el-form-item>
              </div>
              <div  v-bind:class="{'div-frac-13-avg':!isMobileScreen,'div-frac-1':isMobileScreen}">
                <el-form-item v-if="isVictoryFeatsCusPO" label="Product Name" :prop="`purchaseProducts.0.sysProductId`" :rules="rules.reqRule">
                  <el-select
                    v-model="orderForm.purchaseProducts[0].sysProductId"
                    :disabled="isVictoryFeatsAdhocPO || currentView === ORDER_VIEW.VIEW_ORDER || !(orderForm.purchaseProducts[0].status === 'PENDING' || !orderForm.purchaseProducts[0].status)"
                    @visible-change="handleProductNameVisibleChange('BUYER')"
                    @change="handleProductNameChange($event, 0, 'BUYER')"
                  >
                    <el-option
                      v-for="item in productList"
                      :key="item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item v-if="isVictoryFeatsCusPO" label="Nominated Qty" :prop="`purchaseProducts.0.finalQuantity`" :rules="rules.vistoryFinalQtyRule" class="nominatedQty"      >
                  <!--  :disabled="currentView === ORDER_VIEW.VIEW_ORDER && !isEditPurchaseOrder || !(orderForm.purchaseProducts[0].status === 'PENDING' || !orderForm.purchaseProducts[0].status)" -->
                  <el-input-number
                   :disabled="!canEditVictoryFields"
                    v-model="orderForm.purchaseProducts[0].finalQuantity"
                    :controls="false"  :min="null"
                    @change="handleFinalQtyChange(0, 'BUYER')"

                  />
                </el-form-item>
                <el-form-item v-if="isVictoryFeatsCusPO" label="Unit Metric" :prop="`purchaseProducts.0.unitMetric`" :rules="rules.notReqRule">
                  <el-select v-model="orderForm.purchaseProducts[0].unitMetric" :disabled="!canEditVictoryFields">
                    <el-option
                      v-for="item in UNIT_OPTIONS"
                      :key="item.value" :label="item.label" :value="item.value"
                    />
                  </el-select>
                </el-form-item>
                <el-form-item v-if="isVictoryFeatsCusPO" label="Nomination Date" prop="nominationDate" :rules="rules.validateNominationDate">
                  <el-date-picker :disabled="!canEditVictoryFields"
                    type="datetime" :format="DATETIME_FORMAT.GEO_DATETIME"
                    v-model="orderForm.nominationDate"
                    :picker-options="nominationDatePickerOption()"
                    @change="handleNominationDateChange"
                  />
                </el-form-item>
                <el-form-item v-if="(tradeType === ORDER_TRADE_TYPE.PURCHASE || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) &&isVictoryFeatsCusPO" label="Purchase Reference No." prop="buyerReferenceNo">
                  <el-input v-model="orderForm.buyerReferenceNo" :disabled="!canEditVictoryFields"/>
                </el-form-item>
              </div>
              <div class="div-frac-1">
                <el-form-item label="Remark">
                  <el-input v-model="orderForm.remark" type="textarea" :rows="2" :disabled="!canEditVictoryFields"/>
                </el-form-item>
              </div>
            </div>
          </div>
        </template>
        <div v-if="!isShowVictoryFeats && tradeType  === ORDER_TRADE_TYPE.PURCHASE" v-bind:class="{'div-frac-1': isMobileScreen,'div-frac-13-avg':!isMobileScreen}">
          <el-form-item v-if="isOnline" label="Company Name (Seller)" prop="sellerSysOrganizationId" :rules="rules.reqRule">
            <el-select
              v-model="orderForm.sellerSysOrganizationId" filterable value-key="id"
              @change="handleCompanyNameSelect($event, 'SELLER',true)"
              :disabled="currentView === ORDER_VIEW.VIEW_ORDER || currentView === ORDER_VIEW.EDIT_ORDER || linkedPurchaseTermContract !== null"
            >
              <el-option
                v-for="item in creditAssociationList"
                :key="item.id" :label="item.counterpartyCompanyName" :value="item.counterpartySysOrganizationId"
                :disabled="item.status !== 'ACTIVE'"
              />
            </el-select>
          </el-form-item>
          <el-form-item v-else label="Company Name (Seller)">
            <el-input v-model="orderForm.sellerCompanyName" disabled/>
          </el-form-item>
        </div>
        <div v-if="! isVictoryFeatsCusPO" class="basic-card-block" ref="productPriceDiv">
          <h1> {{isOps?'Product Information':'Product and Pricing Information'}}</h1>
          <!-- <h1 v-else></h1> -->
          <div class="product-and-ricing">
            <div class="div-product-other-costs-info-body">
              <div class="flex-between-row margin-bottom-5" style=" align-items: center; ">
                <div>
                  <h4 v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES">Purchase Product and Pricing Information</h4>
                  <div v-if="isShowVictoryFeats && tradeType === ORDER_TRADE_TYPE.PURCHASE " style="color:#30619E; font-weight: 400;  font-size: 13px">*Quantity conversion unit is for reference only</div>
                </div>
                <el-button style="margin: auto 8px;"
                    v-if="((tradeType === ORDER_TRADE_TYPE.PURCHASE && orderForm.orderType === ORDER_TYPE.DELIVERED) || (tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES && orderForm.orderType === ORDER_TYPE.DELIVERED)) && !isViewOnly"
                      type="primary" round size="mini" @click="addProduct('BUYER')" :disabled="currentView === ORDER_VIEW.VIEW_ORDER" >
                    Add
                  </el-button>

              </div>
              <!--  PURCHASE || PURCHASE_AND_SALES -->
              <el-table  ref="purchaseProductTable" v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES"
                :data="orderForm.purchaseProducts" border
              >
                <el-table-column label="No." width="50px">
                  <template v-slot="scope">
                    <h5 class="h5-el-table-col-index">{{scope.$index + 1}}</h5>
                  </template>
                </el-table-column>
                <el-table-column min-width="300px">
                  <template v-slot:header><span>* </span>Product Name</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`purchaseProducts.${scope.$index}.sysProductId`" :rules="rules.reqRule">
                      <el-select
                        v-model="scope.row.sysProductId"
                        :disabled="isVictoryFeatsAdhocPO || currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                        @visible-change="handleProductNameVisibleChange('BUYER')"
                        @change="handleProductNameChange($event, scope.$index, 'BUYER')"
                      >
                        <el-option
                          v-for="item in productList"
                          :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column  min-width="150px">
                  <template v-slot:header><span v-if="isShowVictoryFeats">* </span>Nominated Qty</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`purchaseProducts.${scope.$index}.finalQuantity`" :rules="rules.finalQtyRule">
                      <el-input-number
                        v-model="scope.row.finalQuantity"
                        :controls="false" :precision="3" :min="null"
                        @change="handleFinalQtyChange(scope.$index, 'BUYER')"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER && !isEditPurchaseOrder || !(scope.row.status === 'PENDING' || !scope.row.status)"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column min-width="200px">
                  <template v-slot:header><span v-if="!isShowVictoryFeats">* </span>Specs</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`purchaseProducts.${scope.$index}.sysProductSpecificationId`" :rules="isShowVictoryFeats?rules.notReqRule:rules.reqRule">
                      <el-select
                        v-model="scope.row.sysProductSpecificationId"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                        @visible-change="handleProductSpecsVisibleChange('BUYER')"
                        @change="handleProductSpecsChange($event, scope.$index, 'BUYER')"
                      >
                        <el-option
                          v-for="item in productSpecificationList"
                          :key="item.id" :label="item.name" :value="item.id">
                        </el-option>
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column min-width="150px">
                  <template v-slot:header><span v-if="!isShowVictoryFeats">* </span>Min Qty</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`purchaseProducts.${scope.$index}.minimumQuantity`" :rules="isShowVictoryFeats?rules.notReqmMinQtyRule:rules.minQtyRule">
                      <el-input-number
                        v-model="scope.row.minimumQuantity"
                        :controls="false" :precision="3" :min="null"
                        @change="handleMinQtyChange(scope.$index, 'BUYER')"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column min-width="150px">
                  <template v-slot:header><span v-if="!isShowVictoryFeats">* </span>Max Qty</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`purchaseProducts.${scope.$index}.maximumQuantity`" :rules="isShowVictoryFeats?rules.notReqMaxQtyRule:rules.maxQtyRule">
                      <el-input-number
                        v-model="scope.row.maximumQuantity"
                        :controls="false" :precision="3" :min="null"
                        @change="handleMaxQtyChange(scope.$index, 'BUYER')"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column min-width="120px">
                  <template v-slot:header><span  v-if="!isShowVictoryFeats">* </span>Unit Metric</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`purchaseProducts.${scope.$index}.unitMetric`" :rules="isShowVictoryFeats?rules.notReqRule:rules.reqRule">
                      <el-select v-model="scope.row.unitMetric" :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status) || !isShowVictoryFeats">
                        <el-option
                          v-for="item in UNIT_OPTIONS"
                          :key="item.value" :label="item.label" :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column v-if="!(linkedPurchaseTermContract && linkedPurchaseTermContract.termContractType === 'TIME_CHARTER') && !isOps" min-width="150px">
                  <template v-slot:header><span>* </span>Price (MT)</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`purchaseProducts.${scope.$index}.price`" :rules="rules.reqRule">
                      <el-input-number
                        v-model="scope.row.price"
                        :controls="false" :precision="3" :min="null"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="Currency" min-width="120px">
                  <template v-slot="scope">
                    <el-form-item>
                      <el-select v-model="scope.row.currency" disabled>
                        <el-option
                          v-for="item in currencyList"
                          :key="item.id" :label="item.code" :value="item.code"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column min-width="250px" v-if="orderForm.orderType !== ORDER_TYPE.EX_WHARF">
                  <template v-slot:header><span v-if="!isShowVictoryFeats">* </span>Physical Supplier BDN (Buyer)</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`purchaseProducts.${scope.$index}.physicalSupplierSysOrganizationId`" :rules="isShowVictoryFeats ? rules.notReqRule : rules.reqRule">
                      <el-select
                        v-model="scope.row.physicalSupplierSysOrganizationId" filterable value-key="id"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status) || isShowVictoryFeats"
                        @change="handlePhysicalSupplierBdnChange($event, scope.$index, 'BUYER')"
                      >
                        <el-option
                          v-for="item in physicalSupplierList"
                          :key="item.id" :label="item.companyName" :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column v-if="orderForm.orderType !== ORDER_TYPE.BARGING_NOMINATION && !isShowVictoryFeats" label="Price Type" min-width="120px">
                  <template v-slot="scope">
                    <el-form-item>
                      <el-select
                        v-model="scope.row.priceType"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                        @change="handlePriceTypeSelect($event, scope.row, 'BUYER', scope.$index)"
                      >
                        <el-option
                          v-for="item in ORDER_PRICE_TYPE_OPTIONS"
                          :key="item.value" :label="item.label" :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column v-if="!isOps && orderForm.orderType !== ORDER_TYPE.BARGING_NOMINATION" :width="orderForm.orderType === ORDER_TYPE.EX_WHARF && currentView !== ORDER_VIEW.CREATE_ORDER ? '240px' : 'auto'"/>
                <el-table-column v-if="orderForm.orderType === ORDER_TYPE.DELIVERED || (orderForm.orderType !== ORDER_TYPE.DELIVERED && orderForm.purchaseProducts[0].status && orderForm.purchaseProducts[0].status !== 'PENDING')" label="" width="50px" fixed="right" >
                  <template v-slot="scope">
                    <div>
                      <el-button v-if="scope.row.status === 'PENDING' || !scope.row.status" type="danger" icon="el-icon-delete" size="mini" circle @click="deleteAlert(true,scope.row,scope.$index,'BUYER')" :disabled="currentView === ORDER_VIEW.VIEW_ORDER || orderForm.purchaseProducts.length === 1"/>
                      <el-tooltip v-else class="item" effect="dark" content="Verified" placement="top">
                        <el-button class="el-icon-check" type="success" size="mini" circle/>
                      </el-tooltip>
                    </div>
                  </template>
                </el-table-column>
                <el-table-column v-if="!isOps && orderForm.orderType !== ORDER_TYPE.BARGING_NOMINATION" type="expand" min-width="50px" fixed="right">
                  <template v-slot="scope">
                    <table-col-product-pricing parent='OrderFormPurchaseProduct' :nominationDate="new Date(orderForm.nominationDate)" :scopeRow="scope.row" :scopeIndex="scope.$index" priceProp="purchaseProducts" :priceType="scope.row.priceType" :priceMethodologyList="priceMethodologyList"
                      :rulesReq="rules.reqRule" :notReqRule="rules.notReqRule" @handlePriceInfoChange="handlePriceInfoChange" @handleReferencePriceChange="handleReferencePriceChange"
                      :holidayMap="holidayMap" :resetProductPriceWatcher="resetProductPriceWatcher" :isAllFieldsDisabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                    />
                  </template>
                </el-table-column>
              </el-table>
              <div class="flex-between-row margin-bottom-5" style=" align-items: center; ">
                <div class="margin-top-10"> <h4  v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES">Sales Product and Pricing Information</h4></div>
                <el-button
                  style="margin: auto 8px;"
                  v-if="((tradeType === ORDER_TRADE_TYPE.SALES && orderForm.orderType === ORDER_TYPE.DELIVERED) || (tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES && orderForm.orderType === ORDER_TYPE.DELIVERED)) && !isViewOnly"
                  type="primary" round size="mini" @click="addProduct('SELLER')" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                >
                  Add
                </el-button>
              </div>
              <!-- SALES || PURCHASE_AND_SALES -->
              <el-table ref="salesProductTable" v-if="tradeType === ORDER_TRADE_TYPE.SALES || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES"
                :data="orderForm.salesProducts" border
              >
                <el-table-column label="No." width="50px">
                  <template v-slot="scope">
                    <h5 class="h5-el-table-col-index">{{scope.$index + 1}}</h5>
                  </template>
                </el-table-column>
                <el-table-column min-width="300px">
                  <template v-slot:header><span>* </span>Product Name</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`salesProducts.${scope.$index}.sysProductId`" :rules="rules.reqRule">
                      <el-select v-model="scope.row.sysProductId" :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)" @visible-change="handleProductNameVisibleChange('SELLER')" @change="handleProductNameChange($event, scope.$index, 'SELLER')">
                        <el-option
                          v-for="item in productList"
                          :key="item.id" :label="item.name" :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column min-width="150px">
                  <template v-slot:header><span v-if="isShowVictoryFeats">* </span>Nominated Qty</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`salesProducts.${scope.$index}.finalQuantity`" :rules="rules.finalQtyRule">
                      <el-input-number
                        v-model="scope.row.finalQuantity"
                        :controls="false" :precision="3" :min="null"
                        @change="handleFinalQtyChange(scope.$index, 'SELLER')"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column min-width="200px">
                  <template v-slot:header><span v-if="!isShowVictoryFeats">* </span>Specs</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`salesProducts.${scope.$index}.sysProductSpecificationId`" :rules="isShowVictoryFeats?rules.notReqRule:rules.reqRule">
                      <el-select
                        v-model="scope.row.sysProductSpecificationId"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                        @visible-change="handleProductSpecsVisibleChange('SELLER')"
                        @change="handleProductSpecsChange($event, scope.$index, 'SELLER')"
                      >
                        <el-option
                          v-for="item in productSpecificationList"
                          :key="item.id" :label="item.name" :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column min-width="150px">
                  <template v-slot:header><span v-if="!isShowVictoryFeats">* </span>Min Qty</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`salesProducts.${scope.$index}.minimumQuantity`" :rules="isShowVictoryFeats?rules.notReqmMinQtyRule:rules.minQtyRule">
                      <el-input-number
                        v-model="scope.row.minimumQuantity"
                        :controls="false" :precision="3" :min="null"
                        @change="handleMinQtyChange(scope.$index, 'SELLER')"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column min-width="150px">
                  <template v-slot:header><span v-if="!isShowVictoryFeats">* </span>Max Qty</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`salesProducts.${scope.$index}.maximumQuantity`" :rules="isShowVictoryFeats?rules.notReqMaxQtyRule:rules.maxQtyRule">
                      <el-input-number
                        v-model="scope.row.maximumQuantity"
                        :controls="false" :precision="3" :min="null"
                        @change="handleMaxQtyChange(scope.$index, 'SELLER')"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>

                <el-table-column min-width="120px">
                  <template v-slot:header><span  v-if="!isShowVictoryFeats">* </span>Unit Metric</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`salesProducts.${scope.$index}.unitMetric`" :rules="isShowVictoryFeats ? rules.notReqRule:rules.reqRule">
                      <el-select v-model="scope.row.unitMetric" :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status) || !isShowVictoryFeats">
                        <el-option
                          v-for="item in UNIT_OPTIONS"
                          :key="item.value" :label="item.label" :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column v-if="!(linkedSalesTermContract && linkedSalesTermContract.termContractType === 'TIME_CHARTER') && !isOps" min-width="150px">
                  <template v-slot:header><span>* </span>Price (MT)</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`salesProducts.${scope.$index}.price`" :rules="rules.reqRule">
                      <el-input-number
                        v-model="scope.row.price"
                        :controls="false" :precision="3" :min="null"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                      />
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column label="Currency" min-width="120px" v-if="!isOps">
                  <template v-slot="scope">
                    <el-form-item>
                      <el-select v-model="scope.row.currency" disabled>
                        <el-option
                          v-for="item in currencyList"
                          :key="item.id" :label="item.code" :value="item.code"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column min-width="250px" v-if="orderForm.orderType !== ORDER_TYPE.EX_WHARF">
                  <template v-slot:header><span v-if="!isShowVictoryFeats">* </span>Physical Supplier BDN</template>
                  <template v-slot="scope">
                    <el-form-item :prop="`salesProducts.${scope.$index}.physicalSupplierSysOrganizationId`"  :rules="isShowVictoryFeats ? rules.notReqRule : rules.reqRule">
                      <el-select
                        v-model="scope.row.physicalSupplierSysOrganizationId" filterable value-key="id"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status) ||
                          productsVerifiedList.filter(p => p.sysProductId === scope.row.sysProductId).filter(p => p.sysProductSpecificationId === scope.row.sysProductSpecificationId).length > 0"
                        @change="handlePhysicalSupplierBdnChange($event, scope.$index, 'SELLER')"
                      >
                        <el-option
                          v-for="item in physicalSupplierList"
                          :key="item.id" :label="item.companyName" :value="item.id"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column v-if="orderForm.orderType !== ORDER_TYPE.BARGING_NOMINATION  && !isShowVictoryFeats" label="Price Type" min-width="120px">
                  <template v-slot="scope">
                    <el-form-item>
                      <el-select
                        v-model="scope.row.priceType"
                        :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                        @change="handlePriceTypeSelect($event, scope.row, 'SELLER', scope.$index)"
                      >
                        <el-option
                          v-for="item in ORDER_PRICE_TYPE_OPTIONS"
                          :key="item.value" :label="item.label" :value="item.value"
                        />
                      </el-select>
                    </el-form-item>
                  </template>
                </el-table-column>
                <el-table-column v-if="!isOps && orderForm.orderType !== ORDER_TYPE.BARGING_NOMINATION" :width="orderForm.orderType === ORDER_TYPE.EX_WHARF && currentView !== ORDER_VIEW.CREATE_ORDER ? '240px' : 'auto'"/>
                <el-table-column v-if="orderForm.orderType === ORDER_TYPE.DELIVERED || (orderForm.orderType !== ORDER_TYPE.DELIVERED && orderForm.salesProducts[0].status && orderForm.salesProducts[0].status !== 'PENDING')" label="" width="50px" fixed="right" >
                  <template v-slot="scope">
                    <div>
                      <el-button v-if="scope.row.status === 'PENDING' || !scope.row.status" type="danger" icon="el-icon-delete" size="mini" circle @click="deleteAlert(true,scope.row,scope.$index,'SELLER')" :disabled="currentView === ORDER_VIEW.VIEW_ORDER || orderForm.salesProducts.length === 1"/>
                      <el-tooltip v-else class="item" effect="dark" content="Verified" placement="top">
                        <el-button class="el-icon-check" type="success" size="mini" circle/>
                      </el-tooltip>
                    </div>
                    </template>
                </el-table-column>
                <el-table-column v-if="!isOps && orderForm.orderType !== ORDER_TYPE.BARGING_NOMINATION" type="expand" min-width="50px" width="auto" fixed="right">
                  <template v-slot="scope">
                    <table-col-product-pricing parent='OrderFormSalesProduct' :nominationDate="new Date(orderForm.nominationDate)" :scopeRow="scope.row" :scopeIndex="scope.$index" priceProp="salesProducts" :priceType="scope.row.priceType" :priceMethodologyList="priceMethodologyList"
                      :rulesReq="rules.reqRule" :notReqRule="rules.notReqRule" @handlePriceInfoChange="handlePriceInfoChange" @handleReferencePriceChange="handleReferencePriceChange"
                      :holidayMap="holidayMap" :resetProductPriceWatcher="resetProductPriceWatcher" :isAllFieldsDisabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'PENDING' || !scope.row.status)"
                    />
                  </template>
                </el-table-column>
              </el-table>
            </div>
            <div v-if="isOps">
              <h1>Remarks: {{orderForm.remark || '-'}}</h1>
            </div>
          </div>
        </div>
            <template>
              <div v-if="! isVictoryFeatsCusPO" class="basic-card-block margin-top-0">
              <h1>Basic Information</h1>
              <div>
                <div v-bind:class="{'div-frac-13-avg':!isMobileScreen,'div-frac-12-avg':isMobileScreen}">
                  <el-form-item label="Mitigating Company">
                    <el-input v-model="orderForm.mitigatingCompany" disabled/>
                  </el-form-item>
                  <el-form-item v-if="isOnline" label="Name" prop="name" :rules="rules.reqRule">
                    <el-select v-model="orderForm.name" filterable :disabled="currentView === ORDER_VIEW.VIEW_ORDER || currentView === ORDER_VIEW.EDIT_ORDER">
                      <el-option
                        v-for="item in currentCompanyUserList"
                        :key="item.id" :label="item.name" :value="item.id"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item v-else label="Name" prop="name">
                    <el-input v-model="orderForm.name" disabled/>
                  </el-form-item>
                  <el-form-item label="Order Date" prop="orderDate" :rules="rules.reqRule">
                    <el-date-picker
                      type="datetime"  :format="DATETIME_FORMAT.GEO_DATETIME" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                      v-model="orderForm.orderDate"
                      @change="orderForm.nominationDate = null"
                    />
                  </el-form-item>
                  <el-form-item label="Nomination Date" prop="nominationDate" :rules="rules.validateNominationDate">
                    <el-date-picker
                      type="datetime" :format="DATETIME_FORMAT.GEO_DATETIME" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                      v-model="orderForm.nominationDate"
                      :picker-options="nominationDatePickerOption()"
                      @change="handleNominationDateChange"
                    />
                  </el-form-item>
                  <el-form-item v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES" label="Purchase Reference No." prop="buyerReferenceNo">
                    <el-input v-model="orderForm.buyerReferenceNo" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"/>
                  </el-form-item>
                  <el-form-item v-if="tradeType === ORDER_TRADE_TYPE.SALES || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES" label="Sales Reference No." prop="sellerReferenceNo">
                    <el-input v-model="orderForm.sellerReferenceNo" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"/>
                  </el-form-item>
                </div>
                <el-button
                  class="btn-link-to-term" v-if="isSpot && tradeType !== ORDER_TRADE_TYPE.SALES"
                  round outline size="small"
                  @click="isLinkedSalesTermContract = false, dialogLinkTermContract = true;"
                  :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                >
                <!-- link Purchase Order -->
                  Link to Term {{tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES ? '(Purchase Order)' : ''}}
                </el-button>
                <table-term-contract
                  v-if="isSpot && tradeType !== ORDER_TRADE_TYPE.SALES"
                  hasRightMenu
                  :isRightMenuDisabled="currentView === ORDER_VIEW.VIEW_ORDER"
                  @handleOrderTableRightMenuClick="handleOrderTableRightMenuClick"
                  :orderType="orderForm.orderType"
                  :orderTableData="linkedPurchaseTermContract ? [linkedPurchaseTermContract] : []"
                  parent="LinkedPurchaseOrder"
                />
                <el-button
                  class="btn-link-to-term"
                  v-if="isSpot && tradeType !== ORDER_TRADE_TYPE.PURCHASE"
                  round outline size="small"
                  @click="isLinkedSalesTermContract = true; dialogLinkTermContract = true;"
                  :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                >
                  <!-- link Sales Order -->
                  Link to Term {{tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES ? '(Sales Order)' : ''}}
                </el-button>
                <table-term-contract
                  v-if="isSpot && tradeType !== ORDER_TRADE_TYPE.PURCHASE"
                  hasRightMenu
                  :isRightMenuDisabled="currentView === ORDER_VIEW.VIEW_ORDER"
                  @handleOrderTableRightMenuClick="handleOrderTableRightMenuClick"
                  :orderType="orderForm.orderType"
                  :orderTableData="linkedSalesTermContract ? [linkedSalesTermContract] : []"
                  parent="LinkedSalesOrder"
                />
              </div>
            </div>
            <div v-if="! isVictoryFeatsCusPO" class="basic-card-block">
              <h1>{{tradeType === ORDER_TRADE_TYPE.SALES ? 'Buyer' : tradeType === ORDER_TRADE_TYPE.PURCHASE ? 'Seller' : 'Buyer and Seller'}} Information</h1>
              <div>
                <h4 v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES">Seller Information</h4>
                <div>
                  <div class="div-checkbox"  v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES">
                    <el-checkbox v-model="isSellerGreenlightSupply" :disabled="currentView === ORDER_VIEW.VIEW_ORDER">Green Light Supply</el-checkbox>
                    <el-checkbox :disabled="currentView === ORDER_VIEW.VIEW_ORDER">GST Applicable</el-checkbox>
                  </div>
                </div>
                <div v-bind:class="{'div-frac-13-avg':!isMobileScreen,'div-frac-1':isMobileScreen}" v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES">
                  <el-form-item v-if="isOnline" label="Company Name" prop="sellerSysOrganizationId" :rules="rules.reqRule">
                    <el-select
                      v-model="orderForm.sellerSysOrganizationId" filterable value-key="id"
                      @change="handleCompanyNameSelect($event, 'SELLER')"
                      :disabled="currentView === ORDER_VIEW.VIEW_ORDER || currentView === ORDER_VIEW.EDIT_ORDER || linkedPurchaseTermContract !== null  || tradeType === ORDER_TRADE_TYPE.PURCHASE "
                    >
                      <el-option
                        v-for="item in creditAssociationList"
                        :key="item.id" :label="item.counterpartyCompanyName" :value="item.counterpartySysOrganizationId"
                        :disabled="item.status !== 'ACTIVE'"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item v-else label="Company Name">
                    <el-input v-model="orderForm.sellerCompanyName" disabled/>
                  </el-form-item>
                  <el-form-item v-if="isOnline" label="In-Charge" prop="sellerId" :rules="rules.reqRule">
                    <el-select v-model="orderForm.sellerId" filterable value-key="id"
                      @input="handleBuyerSellerNameSelect($event, 'SELLER')"
                      :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                    >
                      <el-option
                        v-for="item in sortByAlphabetical(sellerCustomerList, 'name')"
                        :key="item.id" :label="item.name" :value="item.id"
                        :disabled="!item.isActive && !creditAssociation.isOnlineCounterparty"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item v-else label="In-Charge">
                    <el-input v-model="orderForm.sellerName" disabled/>
                  </el-form-item>
                  <el-form-item label="Email Address" prop="sellerEmail" :rules="GENERAL_EMAIL_RULE_REQ" >
                    <el-input v-model="orderForm.sellerEmail" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"/>
                  </el-form-item>
                </div>
                <div v-bind:class="{'div-frac-13-avg':!isMobileScreen,'div-frac-12-avg':isMobileScreen}" v-if="(tradeType === ORDER_TRADE_TYPE.PURCHASE || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) && !(linkedPurchaseTermContract && linkedPurchaseTermContract.termContractType === 'TIME_CHARTER')" >
                  <el-form-item label="Payment Terms"  prop="sellerPaymentTerm" :rules="rules.reqRule">
                    <el-select v-model="orderForm.sellerPaymentTerm" :disabled="currentView === ORDER_VIEW.VIEW_ORDER" @change="(e)=>handlePaymentTermChange(e,false)">
                      <el-option
                        v-for="item in PAYMENT_TERM_OPTIONS"
                        :key="item.value" :label="item.label" :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="orderForm.sellerPaymentTerm !== 'SPECIFIC_DATE'" label="Credit Days" prop="sellerCreditDay" :rules="rules.reqRule">
                    <el-input-number
                      v-model="orderForm.sellerCreditDay"
                      :controls="false" :precision="0" :min="0"
                      :disabled="currentView === ORDER_VIEW.VIEW_ORDER || orderForm.sellerPaymentTerm === 'CASH_IN_ADVANCE'"
                    />
                  </el-form-item>
                  <el-form-item v-else label="Payment Term Date" prop="sellerPaymentTermDate" :rules="rules.reqRule">
                    <el-date-picker
                    :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                      type="date" format="dd-MM-yyyy"
                      v-model="orderForm.sellerPaymentTermDate"
                    />
                  </el-form-item>
                </div>
                <h4 v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES">Buyer Information</h4>
                <div v-if="tradeType === ORDER_TRADE_TYPE.SALES || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES">
                  <div class="div-checkbox">
                    <el-checkbox v-model="isBuyerGreenlightSupply" :disabled="currentView === ORDER_VIEW.VIEW_ORDER">Green Light Supply</el-checkbox>
                    <el-checkbox :disabled="currentView === ORDER_VIEW.VIEW_ORDER">GST Applicable</el-checkbox>
                  </div>
                </div>
                <div v-bind:class="{'div-frac-13-avg':!isMobileScreen,'div-frac-1':isMobileScreen}" v-if="tradeType === ORDER_TRADE_TYPE.SALES || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES">
                  <el-form-item v-if="isOnline" label="Company Name" prop="buyerSysOrganizationId" :rules="rules.reqRule">
                    <el-select v-model="orderForm.buyerSysOrganizationId" filterable value-key="id"
                      @change="handleCompanyNameSelect($event, 'BUYER')"
                      :disabled="currentView === ORDER_VIEW.VIEW_ORDER || currentView === ORDER_VIEW.EDIT_ORDER || linkedSalesTermContract !== null"
                    >
                      <el-option
                        v-for="item in creditAssociationList"
                        :key="item.id" :label="item.counterpartyCompanyName" :value="item.counterpartySysOrganizationId"
                        :disabled="item.status !== 'ACTIVE'"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item v-else label="Company Name">
                    <el-input v-model="orderForm.buyerCompanyName" disabled/>
                  </el-form-item>
                  <el-form-item v-if="isOnline" label="In-Charge" prop="buyerId" :rules="rules.reqRule">
                    <el-select v-model="orderForm.buyerId" filterable value-key="id"
                      @input="handleBuyerSellerNameSelect($event, 'BUYER')"
                      :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                    >
                      <el-option
                        v-for="item in sortByAlphabetical(buyerCustomerList, 'name')"
                        :key="item.id" :label="item.name" :value="item.id"
                        :disabled="!item.isActive && !creditAssociation.isOnlineCounterparty"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item v-else label="In-Charge">
                    <el-input v-model="orderForm.buyerName" disabled/>
                  </el-form-item>
                  <el-form-item label="Email Address" prop="buyerEmail" :rules="GENERAL_EMAIL_RULE_REQ" >
                    <el-input v-model="orderForm.buyerEmail" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"/>
                  </el-form-item>
                </div>
                <div v-bind:class="{'div-frac-13-13-16-16':!isMobileScreen,'div-frac-12-avg':isMobileScreen}" v-if="(tradeType === ORDER_TRADE_TYPE.SALES || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) && !(linkedSalesTermContract && linkedSalesTermContract.termContractType === 'TIME_CHARTER')">
                  <el-form-item label="Payment Terms" prop="buyerPaymentTerm" :rules="rules.reqRule">
                    <el-select v-model="orderForm.buyerPaymentTerm" :disabled="currentView === ORDER_VIEW.VIEW_ORDER" @change="(e)=>handlePaymentTermChange(e,true)">
                      <el-option
                        v-for="item in PAYMENT_TERM_OPTIONS"
                        :key="item.value" :label="item.label" :value="item.value"
                      />
                    </el-select>
                  </el-form-item>
                  <el-form-item v-if="orderForm.buyerPaymentTerm !== 'SPECIFIC_DATE'" label="Credit Days" prop="buyerCreditDay" :rules="rules.reqRule">
                    <el-input-number
                      v-model="orderForm.buyerCreditDay"
                      :controls="false" :precision="0" :min="0"
                      :disabled="currentView === ORDER_VIEW.VIEW_ORDER || orderForm.buyerPaymentTerm === 'CASH_IN_ADVANCE'"
                    />
                  </el-form-item>
                  <el-form-item v-else label="Payment Term Date" prop="buyerPaymentTermDate" :rules="rules.reqRule">
                    <el-date-picker
                      :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                      type="date" format="dd-MM-yyyy"
                      v-model="orderForm.buyerPaymentTermDate"
                    />
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="basic-card-block">
              <h1>{{orderForm.orderType === ORDER_TYPE.EX_WHARF ? '' : 'Vessel '}}Lifting Information</h1>
              <div>
                <div class="div-checkbox" v-if="orderForm.orderType !== ORDER_TYPE.EX_WHARF && !isVictoryFeatsCusPO">
                  <el-checkbox v-model="isVesselTBC" :disabled="currentView === ORDER_VIEW.VIEW_ORDER" @change="handleTBCChange">TBC</el-checkbox>
                </div>
                <template v-if="orderForm.orderType === ORDER_TYPE.EX_WHARF">
                  <div v-bind:class="{'div-frac-13':!isMobileScreen,'div-frac-12-avg':isMobileScreen}" >
                    <el-form-item label="Lifting" prop="lifting">
                    <el-input v-model="orderForm.lifting" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"/>
                  </el-form-item>
                  <el-form-item label="Terminal"  prop="">
                    <el-select v-model="orderForm.terminal" filterable :disabled="currentView === ORDER_VIEW.VIEW_ORDER" clearable>
                      <el-option
                        v-for="item in locationList"
                        :key="item.name" :label="item.name" :value="item.name"
                      />
                    </el-select>
                  </el-form-item>
                  </div>
                </template>
                <search-and-create-vessel-block
                  v-else-if="isDeliveryOrBargingNominationJob"
                  :isVesselTBC="isVesselTBC"
                  :currentView="currentView"
                  :searchedVesselList="searchedVesselList"
                  :orderForm="orderForm"
                  :rules="rules"
                  @handleSearchMpaReceivingVessels="handleSearchMpaReceivingVessels"
                  @searchVesselvisibleChange="searchVesselvisibleChange"
                  @handleSelectVessel="handleSelectVessel"
                />
                <div v-bind:class="{'div-frac-13-avg':!isMobileScreen,'div-frac-1':isMobileScreen}" v-if="!isVictoryFeatsCusPO">
                  <el-form-item label="Date Range" prop="dateRange" :rules="rules.reqRule">
                    <el-date-picker
                      v-model="orderForm.dateRange" type="daterange" format="dd-MM-yyyy"
                      start-placeholder="Stem Start Date" end-placeholder="Stem End Date"
                      :picker-options="orderStemDateRangePickerOptions()"
                      :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                    >
                    </el-date-picker>
                  </el-form-item>
                  <el-form-item label="Port" prop="port">
                    <el-input v-model="orderForm.port" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"/>
                  </el-form-item>
                  <el-form-item label="Terms & Conditions" prop="termsAndConditions">
                    <el-input v-model="orderForm.termsAndConditions" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"/>
                  </el-form-item>
                </div>
              </div>
            </div>
            <div class="basic-card-block" v-if="orderForm.orderType !== ORDER_TYPE.EX_WHARF && orderForm.thirdParties.length > 0">
              <h1>Third Party Information</h1>
              <div class="div-third-party-info-body">
                <p>Agent</p>
                <hr>
                <div  v-bind:class="{'div-frac-14':!isMobileScreen,'div-frac-12-avg':isMobileScreen}">
                  <el-form-item label="Company">
                    <el-input v-model="orderForm.thirdParties[0].company" :disabled="currentView === ORDER_VIEW.VIEW_ORDER">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Email" prop="thirdParties.0.email" :rules="GENERAL_EMAIL_RULE">
                    <el-input v-model="orderForm.thirdParties[0].email" :disabled="currentView === ORDER_VIEW.VIEW_ORDER">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Person-In-Charge">
                    <el-input v-model="orderForm.thirdParties[0].personInCharge" :disabled="currentView === ORDER_VIEW.VIEW_ORDER">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Contact No." prop="thirdParties.0.contact" :rules="rules.validatePhone">
                    <phone-input v-model="orderForm.thirdParties[0].contact" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"   size="samll" />
                  </el-form-item>
                </div>
                <p>Surveyor</p>
                <hr>
                <div v-bind:class="{'div-frac-14':!isMobileScreen,'div-frac-12-avg':isMobileScreen}">
                  <el-form-item label="Company">
                    <el-input v-model="orderForm.thirdParties[1].company" :disabled="currentView === ORDER_VIEW.VIEW_ORDER">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Email" prop="thirdParties.1.email" :rules="GENERAL_EMAIL_RULE">
                    <el-input v-model="orderForm.thirdParties[1].email" :disabled="currentView === ORDER_VIEW.VIEW_ORDER">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Person-In-Charge">
                    <el-input v-model="orderForm.thirdParties[1].personInCharge" :disabled="currentView === ORDER_VIEW.VIEW_ORDER">
                    </el-input>
                  </el-form-item>
                  <el-form-item label="Contact No." prop="thirdParties.1.contact" :rules="rules.validatePhone">
                    <phone-input v-model="orderForm.thirdParties[1].contact" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"/>
                  </el-form-item>
                </div>
              </div>
            </div>

            <div class="basic-card-block" v-if="!isOps">
              <h1>Other Costs</h1>
              <div>
                <div class="div-product-other-costs-info-body">
                  <h4 v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES">Purchase Other Costs</h4>

                  <el-button v-if="(tradeType === ORDER_TRADE_TYPE.PURCHASE || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) && !isViewOnly" type="primary" round size="mini"
                  @click="addOtherCost('BUYER')" :disabled="currentView === ORDER_VIEW.VIEW_ORDER" >
                    Add
                  </el-button>
                  <!--  PURCHASE || PURCHASE_AND_SALES -->
                  <el-table v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES" :data="orderForm.purchaseOtherCosts" border>
                    <el-table-column label="No." width="50px">
                      <template v-slot="scope">
                        <h5 class="h5-el-table-col-index">{{scope.$index + 1}}</h5>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="150px">
                    <template v-slot:header><span>* </span>Description</template>
                    <template v-slot="scope">
                      <el-form-item :prop="`purchaseOtherCosts.${scope.$index}.description`" :rules="rules.reqRule">
                        <el-select v-model="scope.row.description" :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'CONFIRMED' || !scope.row.status)" filterable allow-create>
                          <el-option
                            v-for="item in ORDER_OTHER_COST_DESCRIPTION_OPTIONS"
                            :key="item.value" :label="item.label" :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </template>
                    </el-table-column>
                    <el-table-column min-width="200px">
                    <template v-slot:header>Product Name</template>
                    <template v-slot="scope">
                      <el-form-item :prop="`purchaseOtherCosts.${scope.$index}.orderProductId`" :rules="rules.notReqRule">
                        <el-select v-model="scope.row.orderProductId" :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'CONFIRMED' || !scope.row.status)" filterable clearable>
                          <el-option
                            v-for="item in purchaseOtherCostProDropDownList"
                            :key="item.id" :label="item.displayName" :value="item.orderProductId"
                          />
                        </el-select>
                      </el-form-item>
                    </template>
                    </el-table-column>
                    <el-table-column min-width="100px">
                    <template v-slot:header><span>* </span>Quantity</template>
                    <template v-slot="scope">
                      <el-form-item :prop="`purchaseOtherCosts.${scope.$index}.quantity`" :rules="rules.reqGreaterThanZeroRule">
                        <el-input-number
                          v-model="scope.row.quantity"
                          :controls="false" :precision="2" :min="null"
                          :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'CONFIRMED' || !scope.row.status)"
                          @blur="calculateOtherCostAmount(scope.$index,true)"
                        />
                      </el-form-item>
                    </template>
                    </el-table-column>
                    <el-table-column min-width="100px">
                    <template v-slot:header><span>* </span>Unit Price</template>
                    <template v-slot="scope">
                      <el-form-item :prop="`purchaseOtherCosts.${scope.$index}.unitPrice`" :rules="rules.reqGreaterThanZeroRule">
                        <el-input-number
                          v-model="scope.row.unitPrice"
                          :controls="false" :precision="2" :min="null"
                          :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'CONFIRMED' || !scope.row.status)"
                          @blur="calculateOtherCostAmount(scope.$index,true)"
                        />
                      </el-form-item>
                    </template>
                    </el-table-column>
                    <el-table-column min-width="100px">
                      <template v-slot:header><span>* </span>Amount</template>
                      <template v-slot="scope">
                        <el-form-item :prop="`purchaseOtherCosts.${scope.$index}.cost`" :rules="rules.reqRule">
                        <el-input-number
                          v-model="scope.row.cost"
                          :controls="false" :precision="2" :min="null"
                          :disabled="true"
                        />
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="100px">
                      <template v-slot:header><span>* </span>Currency</template>
                      <template v-slot="scope">
                        <el-form-item :prop="`purchaseOtherCosts.${scope.$index}.currency`" :rules="rules.reqRule">
                          <el-select v-model="scope.row.currency" disabled>
                            <el-option
                              v-for="item in currencyList"
                              :key="item.id" :label="item.code" :value="item.code"
                            />
                          </el-select>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column label="" width="50px" fixed="right">
                      <template v-slot="scope">
                        <div>
                          <el-button v-if="scope.row.status === 'CONFIRMED' || !scope.row.status" type="danger" icon="el-icon-delete" size="mini" circle :disabled="currentView === ORDER_VIEW.VIEW_ORDER" @click="deleteAlert(false,scope.row,scope.$index, 'BUYER')"/>
                          <el-tooltip v-else class="item" effect="dark" content="Verified" placement="top">
                            <el-button class="el-icon-check" type="success" size="mini" circle/>
                          </el-tooltip>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                  <h4 class="h4-btm" v-if="tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES">Sales Other Costs</h4>
                  <el-button v-if="(tradeType === ORDER_TRADE_TYPE.SALES || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) && !isViewOnly" type="primary" round size="mini"
                    @click="addOtherCost('SELLER')" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"
                  >
                    Add
                  </el-button>
                  <!-- SALES || PURCHASE_AND_SALES -->
                  <el-table v-if="tradeType === ORDER_TRADE_TYPE.SALES || tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES" :data="orderForm.salesOtherCosts" border  ref="refTable">
                    <el-table-column label="No." width="50px">
                      <template v-slot="scope">
                        <h5 class="h5-el-table-col-index">{{scope.$index + 1}}</h5>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="150px">
                    <template v-slot:header><span>* </span>Description</template>
                    <template v-slot="scope">
                      <el-form-item :prop="`salesOtherCosts.${scope.$index}.description`" :rules="rules.reqRule">
                        <el-select v-model="scope.row.description" :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'CONFIRMED' || !scope.row.status)" filterable allow-create>
                          <el-option
                            v-for="item in ORDER_OTHER_COST_DESCRIPTION_OPTIONS"
                            :key="item.value" :label="item.label" :value="item.value"
                          />
                        </el-select>
                      </el-form-item>
                    </template>
                    </el-table-column>
                    <el-table-column min-width="200px">
                    <template v-slot:header>Product Name</template>
                    <template v-slot="scope">
                      <el-form-item :prop="`salesOtherCosts.${scope.$index}.orderProductId`" :rules="rules.notReqRule">
                        <el-select v-model="scope.row.orderProductId" :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'CONFIRMED' || !scope.row.status)" filterable clearable>
                          <el-option
                            v-for="item in salesOtherCostProDropDownList"
                            :key="item.id" :label="item.displayName" :value="item.orderProductId"
                          />
                        </el-select>
                      </el-form-item>
                    </template>
                    </el-table-column>
                    <el-table-column min-width="100px">
                      <template v-slot:header><span>* </span>Quantity</template>
                      <template v-slot="scope">
                        <el-form-item :prop="`salesOtherCosts.${scope.$index}.quantity`" :rules="rules.reqGreaterThanZeroRule">
                        <el-input-number
                          v-model="scope.row.quantity"
                          :controls="false" :precision="2" :min="null"
                          :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'CONFIRMED' || !scope.row.status)"
                          @blur="calculateOtherCostAmount(scope.$index,false)"
                        />
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="100px">
                      <template v-slot:header><span>* </span> Unit Price</template>
                      <template v-slot="scope">
                        <el-form-item :prop="`salesOtherCosts.${scope.$index}.unitPrice`" :rules="rules.reqGreaterThanZeroRule">
                        <el-input-number
                          v-model="scope.row.unitPrice"
                          :controls="false" :precision="2" :min="null"
                          :disabled="currentView === ORDER_VIEW.VIEW_ORDER || !(scope.row.status === 'CONFIRMED' || !scope.row.status)"
                          @blur="calculateOtherCostAmount(scope.$index,false)"
                        />
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="100px">
                      <template v-slot:header><span>* </span>Amount</template>
                      <template v-slot="scope">
                        <el-form-item :prop="`salesOtherCosts.${scope.$index}.cost`" :rules="rules.reqGreaterThanZeroRule">
                        <el-input-number
                          v-model="scope.row.cost"
                          :controls="false" :precision="2" :min="null"
                          :disabled="true"
                        />
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column min-width="120px">
                      <template v-slot:header><span>* </span>Currency</template>
                      <template v-slot="scope">
                        <el-form-item :prop="`salesOtherCosts.${scope.$index}.currency`" :rules="rules.reqRule">
                          <el-select v-model="scope.row.currency" disabled>
                            <el-option
                              v-for="item in currencyList"
                              :key="item.id" :label="item.code" :value="item.code"
                            />
                          </el-select>
                        </el-form-item>
                      </template>
                    </el-table-column>
                    <el-table-column label="" width="50px" fixed="right">
                      <template v-slot="scope">
                        <div>
                          <el-button v-if="scope.row.status === 'CONFIRMED' || !scope.row.status" type="danger" icon="el-icon-delete" size="mini" circle :disabled="currentView === ORDER_VIEW.VIEW_ORDER" @click="deleteAlert(false,scope.row,scope.$index, 'SELLER')"/>
                          <el-tooltip v-else class="item" effect="dark" content="Verified" placement="top">
                            <el-button class="el-icon-check" type="success" size="mini" circle/>
                          </el-tooltip>
                        </div>
                      </template>
                    </el-table-column>
                  </el-table>
                </div>
              </div>
            </div>
            <div class="basic-card-block" v-if="!isOps">
              <h1>Remarks</h1>
              <div>
                <div class="div-remarks-info-body">
                  <el-form-item>
                    <el-input v-model="orderForm.remark" type="textarea" :rows="4" :disabled="currentView === ORDER_VIEW.VIEW_ORDER"/>
                  </el-form-item>
                </div>
              </div>
            </div>
            </template>
          <!-- </el-collapse-item>
        </el-collapse> -->
      </el-form>
      <order-commission-form ref="orderCommission" v-else :currencyList="currencyList" :creditAssociationList="creditAssociationList" :productList="productList" :tradeType="tradeType"
        :orderProductList="orderForm.products" :propsView="currentView" :orderCommission="orderCommission" :resetOrderComm="resetOrderComm"
      />
      <div class="div-form-main-right-summary" v-if="currentView !== ORDER_VIEW.CREATE_ORDER && isOrderSideBar">
        <div>
          <div class="div-order-summary-main">
            <h4>{{tradeType === ORDER_TRADE_TYPE.SALES ? 'Sales Order No.' : 'Purchase Order No.'}}</h4>
            <div class="div-order-summary-order-no">
              <h1>{{tradeType === ORDER_TRADE_TYPE.SALES ? orderForm.salesOrderNo : orderForm.purchaseOrderNo}}</h1>
              <i v-if="hasViewPdfDropdownItem()" class="el-icon-document" @click="handleGenerateOrderPdf()"/>
            </div>
            <div>
              <h3>Order Status</h3>
              <div class="status-tag" :style="getStatusTagStyle(orderForm.sellerSysOrganizationId === myCompany.id ? orderForm.sellerOrderStatus : orderForm.buyerOrderStatus)">{{orderForm.sellerSysOrganizationId === myCompany.id ? capitalizeFirstLetter(orderForm.sellerOrderStatus, '_') : capitalizeFirstLetter(orderForm.buyerOrderStatus, '_')}}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <dialog-link-term-contract :buyerSysOrganizationId="orderForm.buyerSysOrganizationId" :sellerSysOrganizationId="orderForm.sellerSysOrganizationId" :propsView="currentView" :isLinkedSalesTermContract="isLinkedSalesTermContract" :linkedSalesTermContract="linkedSalesTermContract" :linkedPurchaseTermContract="linkedPurchaseTermContract" :dialogLinkTermContract="dialogLinkTermContract" @handleLinkTermContractDialogClose="handleLinkTermContractDialogClose" @handleLinkTermContractConfirm="handleLinkTermContractConfirm" :orderType="orderForm.orderType"/>
    <dialog-verify-order :orderType="orderType" :nominationDate="new Date(orderForm.nominationDate)" :holidayMap="holidayMap" :currencyList="currencyList" :orderId="orderId" :dialogVerifyOrder.sync="dialogVerifyOrder" :priceMethodologyList="priceMethodologyList" @handleVerifyOrderDialogConfirm="handleVerifyOrder" @handleVerifyOrderDialogClose="dialogVerifyOrder = false"/>
  </div>
</template>
<script>
import { mapState } from 'vuex'
import OrderCommissionForm from './OrderCommissionForm'
import { getStatusTagStyle, sortByAlphabetical, capitalizeFirstLetter, numberWithCommas, getUuiD, openBlobUrlInNewTab, isValidImoNo,convertMapKeyValue, serverSGDateTimeFormat } from '@/utils'
import { ORDER_STATUS, ORDER_VIEW, ORDER_PRICE_TYPE, PAYMENT_TERM_OPTIONS, UNIT_OPTIONS, ORDER_PRICE_RATE_TYPE_OPTIONS, ORDER_PRICE_RATE_TYPE_METRIC_OPTIONS, ORDER_PRICE_PERIOD, ORDER_PRICE_TYPE_OPTIONS, ORDER_PRICE_PREMIUM_TYPE_OPTIONS, ORDER_OTHER_COST_DESCRIPTION_OPTIONS, ORDER_MODE_OPTIONS, ORDER_TYPE, ORDER_TRADE_TYPE,TERM_CONTRACT_TYPE,SAGE_GROUP_CODE_MAP,VICTORY_SG_PRODUCT,DATETIME_FORMAT,LICENSE_NUMBKER_RULE,GENERAL_EMAIL_RULE,GENERAL_EMAIL_RULE_REQ  } from '@/constants.js'
import DialogVerifyOrder from './DialogVerifyOrder'
import DialogLinkTermContract from './DialogLinkTermContract'
import TableColProductPricing from './TableColProductPricing'
import { getOrderById, createOrder, updateOrder, deleteOrder, confirmOrder, cancelOrder, verifyOrder, verifyOrderCA, updateOrderComm, generateOrderPdf, updateOrderProductFinalQty, isOrderCancellable } from '@/services/modules/order.js'
import TableTermContract from './TableTermContract'
import { getFileBlobUrl, getReceivingVesselListBySearch } from '@/services/modules/comm.js'

import BargeOrVesselSelector from '../../businessManager/operations/components/BargeOrVesselSelector.vue'
import SearchAndCreateVesselBlock from './SearchAndCreateVesselBlock.vue'
import { getAvailableBarges,} from "@/services/modules/ops.js";
import baseMixin from "@/mixins/base-mixin";
export default {
  name: 'OrderForm',
  mixins: [baseMixin],
  components: {
    DialogVerifyOrder,
    TableColProductPricing,
    OrderCommissionForm,
    TableTermContract,
    DialogLinkTermContract,
    BargeOrVesselSelector,
    SearchAndCreateVesselBlock
  },
  props: {
    propsView: String,
    orderType: String,
    propsTradeType: String,
    orderId: String,
    offlineOrganizationId: String,
    isViewOnly: Boolean,
    isOrderSideBar: Boolean,
    isOrderTopBar: Boolean,
    isOps: Boolean,
    isAllVerified: Boolean
  },
  data () {
    const rules = {
    //  reqRule: [{ required: true, message: 'This field is required', trigger: ['blur', 'change'],transform(value) { return value.trim();}}],
      reqRule: [{ required: true, message: 'This field is required', trigger: ['blur', 'change']}],
      reqGreaterThanZeroRule: [{ required: true, validator: this.reqGreaterThanZeroValidation, trigger: ['blur'] }],
      minQtyRule: [{ required: true, validator: this.minQtyValidation, trigger: ['blur'] }],
      notReqmMinQtyRule: [{ required: false, validator: this.minQtyValidation, trigger: ['blur'] }],
      maxQtyRule: [{ required: true, validator: this.maxQtyValidation, trigger: ['blur'] }],
      notReqMaxQtyRule: [{ required: false, validator: this.maxQtyValidation, trigger: ['blur'] }],
      finalQtyRule: [{ required: false, validator: this.finalQtyValidation, trigger: ['blur'] }],
      vistoryFinalQtyRule: [{ required: true, message: 'This field is required', trigger: ['blur', 'change'] }, { required: false, validator: this.finalQtyValidation, trigger: ['blur'] }],
      notReqRule: [{ required: false }],
      validatePhone: [
        {
          required: false,
          validator: (rule, value, callback) => {
            if(value) {
              const pattern = /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/gm
              if(! pattern.test(value)) {
                callback('Please enter a valid Contact No.')
              }
            } else {
              callback()
            }
          },
          trigger: ['blur', 'change']
        }
      ],
      vesselImoNo: [
        {
          validator: (rule, value, callback) => {
            if (!(!value || value.trim() === '') && !isValidImoNo(value)) {
              callback('Invalid IMO No.')
            }
            callback()
          },
          trigger: ['change', 'blur']
        }
      ],
      validateNominationDate: [
        {
          required: true,
          validator: (rule, value, callback) => {
            if(value) {
              const orderDate = this.$moment(this.orderForm.orderDate);
              if(this.$moment(value).isBefore(orderDate)) {
                callback('Nomination Date can not be earlier than Order Date')
              }
            }
            callback()
          },
          trigger: ['blur', 'change']
        }
      ]
    }
    const hasEditButton = () => {
      return !this.isEditPurchaseOrder && !this.isViewOnly && this.currentView === ORDER_VIEW.VIEW_ORDER && !this.isAllVerified && this.hasPermission(this.$permissionFunctionCode.EDIT) && (
        ((this.orderForm.orderType !== this.ORDER_TYPE.EX_WHARF || (this.orderForm.orderType === this.ORDER_TYPE.EX_WHARF && this.orderForm.createdBySysOrganization === this.myCompany.id)) && this.tradeType === ORDER_TRADE_TYPE.SALES &&
          (this.orderForm.sellerOrderStatus === ORDER_STATUS.CONFIRMED || this.orderForm.sellerOrderStatus === ORDER_STATUS.DRAFT || this.orderForm.sellerOrderStatus === ORDER_STATUS.CREDIT_AUTHORIZATION_REJECTED)) ||
        ((this.orderForm.orderType !== this.ORDER_TYPE.EX_WHARF || (this.orderForm.orderType === this.ORDER_TYPE.EX_WHARF && this.orderForm.createdBySysOrganization === this.myCompany.id)) &&
        this.tradeType === ORDER_TRADE_TYPE.PURCHASE && (this.orderForm.buyerOrderStatus === ORDER_STATUS.CONFIRMED || this.orderForm.buyerOrderStatus === ORDER_STATUS.DRAFT))
      )
    }
    const hasCancelDropdownItem = () => {
      return isOrderCancellable && this.hasPermission(this.$permissionFunctionCode.EDIT)
    }
    const hasConfirmDropdownItem = () => {
      return ((this.tradeType === ORDER_TRADE_TYPE.SALES && this.orderForm.sellerOrderStatus === ORDER_STATUS.PENDING_SELLER_CONFIRMATION) ||
        (this.tradeType === ORDER_TRADE_TYPE.PURCHASE && this.orderForm.buyerOrderStatus === ORDER_STATUS.PENDING_BUYER_CONFIRMATION)) &&
        this.hasPermission(this.$permissionFunctionCode.EDIT)
    }
    const hasDeleteDropdownItem = () => {
      return ((this.tradeType === ORDER_TRADE_TYPE.SALES && this.orderForm.sellerOrderStatus === ORDER_STATUS.DRAFT) ||
        (this.tradeType === ORDER_TRADE_TYPE.PURCHASE && this.orderForm.buyerOrderStatus === ORDER_STATUS.DRAFT)) &&
        this.hasPermission(this.$permissionFunctionCode.DELETE)
    }
    const hasVerifyDropdownItem = () => {
      return !this.isAllVerified && this.tradeType === ORDER_TRADE_TYPE.SALES && this.orderForm.sellerOrderStatus === ORDER_STATUS.CONFIRMED &&
        this.hasPermission(this.$permissionFunctionCode.EDIT)
    }
    const hasViewPdfDropdownItem = () => {
      return this.orderForm.orderType === this.ORDER_TYPE.DELIVERED && ((this.tradeType === ORDER_TRADE_TYPE.SALES && this.orderForm.sellerOrderStatus !== ORDER_STATUS.DRAFT) ||
        (this.tradeType === ORDER_TRADE_TYPE.PURCHASE && this.orderForm.buyerOrderStatus !== ORDER_STATUS.DRAFT))
    }
    const productPrice = {
      priceRateType: null,
      priceRateTypeMetric: 'MID',
      priceMethodologyId: null,
      pricePeriod: null,
      priceStartDate: null,
      priceEndDate: null,
      isPriceFactorFirst: false,
      priceConversionRate: 1,
      pricePremium: null,
      priceUnitMetric: 'MT',
      priceRemark: null,
      referencePrice: null
    }
    const orderFormProduct = {
      sysProductId: null,
      sysProductSpecificationId: null,
      minimumQuantity: null,
      maximumQuantity: null,
      finalQuantity: null,
      unitMetric: 'MT',
      priceType: 'FIXED',
      price: null,
      currency: 'USD',
      physicalSupplierSysOrganizationId: null,
      priceRateType: null,
      priceRateTypeMetric: 'MID',
      priceMethodologyId: null,
      pricePeriod: null,
      priceStartDate: null,
      priceEndDate: null,
      pricePremium: null,
      priceRemark: null,
      priceUnitMetric: 'MT',
      priceConversionRate: 1,
      isPriceFactorFirst: false,
      isPriceEndDateDisabled: false
    }
    const locationOptionProps = {
      value: "name",
      label: "name",
      children: "children",
    };

    return {
      GENERAL_EMAIL_RULE, GENERAL_EMAIL_RULE_REQ, LICENSE_NUMBKER_RULE, SAGE_GROUP_CODE_MAP,VICTORY_SG_PRODUCT,DATETIME_FORMAT,
      orderFormTabActive: 'ORDER_INFO',
      isOnline1: Object.keys(this.$store.state.currentCompany).length > 0,
      holidayMap: new Map(),
      rules,
      tradeType: null,
      isOrderFormLoading: true,
      linkedPurchaseTermContract: null,
      linkedSalesTermContract: null,
      dialogLinkOrder: false,
      dialogLinkTermContract: false,
      creditAssociation: null,
      buyerCustomerList: [],
      sellerCustomerList: [],
      priceMethodologyList: [],
      orderFormProduct,
      orderForm: {
        // Initialize the pricingDate with no time
        pricingDate: new Date(new Date().setHours(0, 0, 0, 0)),
        nominationDate: new Date(new Date().setHours(0, 0, 0, 0)),
        orderDate: new Date(new Date().setHours(0, 0, 0, 0)),
        name: this.$store.state.userExt.id,
        buyerSysOrganizationId: null,
        buyerId: null,
        buyerEmail: null,
        buyerReferenceNo: null,
        sellerReferenceNo: null,
        sellerSysOrganizationId: null,
        sellerId: null,
        sellerEmail: null,
        sellerPaymentTerm: null,
        buyerPaymentTerm: null,
        sellerCreditDay: null,
        buyerCreditDay: null,
        sellerPaymentTermDate: null,
        buyerPaymentTermDate: null,
        stemStartDate: new Date(new Date().setHours(0, 0, 0, 0)),
        stemEndDate: new Date(new Date().setHours(23, 59, 59, 999)),
        dateRange: [new Date(new Date().setHours(0, 0, 0, 0)),new Date(new Date().setHours(23, 59, 59, 999))],
        vesselName: null,
        vesselImoNo: null,
        vesselShipId: null,
        vesselLicenseNo: null,
        port: 'Singapore',
        termsAndConditions: 'Basis seller\'s terms and conditions unless specified',
        lifting: null,
        terminal: null,
        thirdParties: [{
          type: 'AGENT',
          company: null,
          email: null,
          personInCharge: null,
          contact: null
        }, {
          type: 'SURVEYOR',
          company: null,
          email: null,
          personInCharge: null,
          contact: null
        }],
        remark: null,
        purchaseProducts:[orderFormProduct],
        salesProducts: [orderFormProduct],
        purchaseOtherCosts: [],
        salesOtherCosts: [],
        mitigatingCompany: this.$store.state.currentCompany.companyName,
        nextPort: 'Singapore',
        territory: null,
        locationLevel: [],
        bunkerBarge: null, // imo
        bargeShipId: null, // bargeShipId
        bunkerBargeName: null, // name
        bargeName: null, // name
        isVictoryCust: false,
        supplyType: null,
      },
      ORDER_VIEW,
      ORDER_PRICE_PERIOD,
      ORDER_PRICE_PREMIUM_TYPE_OPTIONS,
      ORDER_TRADE_TYPE,
      ORDER_TYPE,
      ORDER_PRICE_TYPE,
      ORDER_STATUS,
      PAYMENT_TERM_OPTIONS: Object.freeze(PAYMENT_TERM_OPTIONS),
      UNIT_OPTIONS: Object.freeze(UNIT_OPTIONS),
      ORDER_PRICE_RATE_TYPE_OPTIONS: Object.freeze(ORDER_PRICE_RATE_TYPE_OPTIONS),
      ORDER_PRICE_RATE_TYPE_METRIC_OPTIONS: Object.freeze(ORDER_PRICE_RATE_TYPE_METRIC_OPTIONS),
      ORDER_PRICE_TYPE_OPTIONS: Object.freeze(ORDER_PRICE_TYPE_OPTIONS),
      ORDER_OTHER_COST_DESCRIPTION_OPTIONS: Object.freeze(ORDER_OTHER_COST_DESCRIPTION_OPTIONS),
      ORDER_MODE_OPTIONS: Object.freeze(ORDER_MODE_OPTIONS),
      isSpot: true,
      isNewVessel: false,
      isVesselTBC: false,
      isBuyerGreenlightSupply: false,
      isSellerGreenlightSupply: false,
      dialogVerifyOrder: false,
      isLinkedSalesTermContract: false,
      productPrice,
      resetProductPriceWatcher: false,
      isEditPurchaseOrder: false,
      deliveredProductTableData: [],
      isDeliveredProductTableLoading: false,
      tempOrder: null,
      orderCommission: null,
      resetOrderComm: false,
      isOrderCancellable: false,
      tempProductList: [],
      productsVerifiedList: [],
      salesOtherCostProDropDownList: [],
      purchaseOtherCostProDropDownList: [],
      hasEditButton,
      hasCancelDropdownItem,
      hasConfirmDropdownItem,
      hasDeleteDropdownItem,
      hasVerifyDropdownItem,
      hasViewPdfDropdownItem,
      searchedVesselList: [],
      vesselSearchKey: '',
      locationOptionProps,
      availableBargesOptions: [],
      availableBargesLoading: true,
      creditLimit:null,
      todayPricing:null,
      sellerHasVictoryFeats:false,
      locationOptions:[],
      selfCollectKioskLocationOptions:[],
      selfCollectKioskShips:[],
      defaultVictorySGSysProductId:null,
      defaultSysProductSpecId:null,
      isSeller: false,
      currentView: null,
      nextPortDisabled: false,
    }
  },
  computed: {
    ...mapState([
      'currencyList',
      'currentCompanyUserList',
      'creditAssociationList',
      'locationList',
      'productList',
      'productSpecificationList',
      'currentCompany',
      'currentCompanySettings',
      'physicalSupplierList',
      'isMobileScreen',
    ]),
    formattedPricingDate () {
      return this.formatDate(this.pricingDate)
    },
    isOnline () {
      return Object.keys(this.$store.state.currentCompany).length > 0
    },
    myCompany () {
      return this.$store.state.currentCompany
    },
    isDeliveryOrBargingNominationJob () {
      return this.orderForm.orderType === this.ORDER_TYPE.DELIVERED || this.orderForm.orderType === this.ORDER_TYPE.BARGING_NOMINATION
    },
    isShowVictoryFeats(){
      const isShow = (this.currentCompanySettings.pricingTierCheck  || this.sellerHasVictoryFeats) && this.orderForm.orderType === this.ORDER_TYPE.DELIVERED
      return isShow
    },
    isVictoryFeatsSelfCollectKiosk(){
      return this.orderForm.orderType === this.ORDER_TYPE.DELIVERED && this.orderForm.territory == 1 && ((this.tradeType === this.ORDER_TRADE_TYPE.PURCHASE && this.sellerHasVictoryFeats) || (this.tradeType === this.ORDER_TRADE_TYPE.SALES && !!this.currentCompanySettings?.pricingTierCheck ))
    },
    isVictoryFeatsCusPO(){
      return (this.orderForm.orderType === this.ORDER_TYPE.DELIVERED) && (this.tradeType === this.ORDER_TRADE_TYPE.PURCHASE) && this.sellerHasVictoryFeats
    },
    isVictoryFeatsAdhocPO(){
      return this.isVictoryFeatsCusPO && (this.currentCompany?.groupCode == this.SAGE_GROUP_CODE_MAP['ADHOC'])
    },
    canEditVictoryFields(){

      return  (this.isEditPurchaseOrder || this.currentView === this.ORDER_VIEW.CREATE_ORDER) || (this.tradeType === ORDER_TRADE_TYPE.SALES && this.currentCompanySettings.pricingTierCheck && this.currentView === this.ORDER_VIEW.EDIT_ORDER);
    }


  },
  created () {
    this.isOrderFormLoading = true
    this.currentView = this.propsView
    const promises = []
    promises.push( this.$store.dispatch('getPhysicalSupplierList'))
    promises.push(this.$store.dispatch('getCurrentCompanyUserList', { orgId: this.myCompany.id }))
    if (this.isOnline) { promises.push(this.$store.dispatch('getCreditAssociationList')) }
    if (!this.currencyList) { promises.push(this.$store.dispatch('getCurrencyList')) }
    if (!this.locationList) { promises.push(this.$store.dispatch('getLocationList')) }
    if (!this.productList) { promises.push(this.$store.dispatch('getProductList')) }
    if (!this.productSpecificationList) { promises.push(this.$store.dispatch('getProductSpecificationList')) }

    Promise.all(promises).then(data => {

      if (this.myCompany.licenseType) {
        const physicalSupplier = this.physicalSupplierList.find(d => d.id === this.myCompany.id).id
        this.orderForm.purchaseProducts[0].physicalSupplierSysOrganizationId =physicalSupplier
        this.orderForm.salesProducts[0].physicalSupplierSysOrganizationId = physicalSupplier
      }

      this.defaultSysProductSpecId = this.productSpecificationList.find(d => !d.year)?.id ?? this.productSpecificationList.find(d => d.year =='2010')?.id;
      this.defaultVictorySGSysProductId =  this.productList.find(d => d?.name && (d?.name?.trim() == this.VICTORY_SG_PRODUCT))?.id;

      if (this.currentView === ORDER_VIEW.VIEW_ORDER) {
        this.isDeliveredProductTableLoading = true
        getOrderById(this.orderId).then(res => {
          if (res?.code === 1000) {
            const order = res.data
            this.getOtherCostProDropDownList(this.tradeType === ORDER_TRADE_TYPE.PURCHASE, order.products)
            this.tempOrder = this.$_.cloneDeep(order)
            this.handleLocationOption(true)
            this.populateOrderForm(order)
            this.populateDeliveredTable()
            this.populateCommissionForm(order)
            isOrderCancellable(order.id).then(res => {
              this.isOrderCancellable = res.data
            })
          }
        }).catch(e => {})
      } else {
        this.orderForm.purchaseProducts[0].sysProductSpecificationId = this.defaultSysProductSpecId
        this.orderForm.orderType = this.orderType
        this.tradeType = this.propsTradeType
        /// for victory funcs set default form data  ---start
        if (this.orderForm.orderType == this.ORDER_TYPE.DELIVERED && this.isShowVictoryFeats && this.tradeType == this.ORDER_TRADE_TYPE.PURCHASE ) {
          this.orderForm.purchaseProducts[0].physicalSupplierSysOrganizationId = null;

        }
        /// for victory funcs  ---end
        this.isOrderFormLoading = false
      }
    })

    this.setHolidays()
    this.getAllMethodology()
  },
  methods: {
    serverSGDateTimeFormat,
    formatDate (date) {
      const day = String(date.getDate()).padStart(2, '0')
      const month = String(date.getMonth() + 1).padStart(2, '0')
      const year = date.getFullYear()
      return `${day}-${month}-${year}`
    },
    isValidImoNo,
    getStatusTagStyle,
    capitalizeFirstLetter,
    sortByAlphabetical,
    numberWithCommas,
    getFileBlobUrl,
    openBlobUrlInNewTab,
    getReceivingVesselListBySearch,
    previewFile (fileId) {
      this.getFileBlobUrl(fileId).then(url => {
        this.openBlobUrlInNewTab(url)
      }).catch(e => {

      })
    },
    populateCommissionForm (order) {
      this.orderCommission = {
        brokerSysOrganizationId: this.$_.cloneDeep(order.brokerSysOrganizationId),
        brokerCompanyName: this.$_.cloneDeep(order.brokerCompanyName),
        brokerId: this.$_.cloneDeep(order.brokerId),
        brokerName: this.$_.cloneDeep(order.brokerName),
        commissions: this.$_.cloneDeep(order.commissions)
      }
    },
    populateOrderForm (order) {
      this.productsVerifiedList = order.products.filter(p => p.status !== 'PENDING')
      if (this.isOnline) {
        order.sellerSysOrganizationId === this.myCompany.id ? this.tradeType = ORDER_TRADE_TYPE.SALES : this.tradeType = ORDER_TRADE_TYPE.PURCHASE
        this.tradeType === ORDER_TRADE_TYPE.SALES ? this.handleCompanyNameSelect(order.buyerSysOrganizationId, 'BUYER') : this.handleCompanyNameSelect(order.sellerSysOrganizationId, 'SELLER')
        this.orderForm = order
        this.orderForm.mitigatingCompany = this.myCompany.companyName
      } else {
        this.orderForm = order
        order.sellerSysOrganizationId === this.offlineOrganizationId ? this.tradeType = ORDER_TRADE_TYPE.SALES : this.tradeType = ORDER_TRADE_TYPE.PURCHASE
        this.tradeType === ORDER_TRADE_TYPE.SALES ? this.orderForm.mitigatingCompany = order.sellerCompanyName : this.orderForm.mitigatingCompany = order.buyerCompanyName
      }

      if (this.tradeType === ORDER_TRADE_TYPE.SALES) {
        this.orderForm.salesProducts = order.products
        if (this.orderForm.salesProducts?.length > 0) {
          this.orderForm.salesProducts.forEach(product => {
            product.sysProductSpecificationId ??= this.defaultSysProductSpecId
          })
        }else{
          this.orderForm.salesProducts[0] = this.defaultSysProductSpecId
        }
        this.orderForm.salesOtherCosts = order.otherCosts
        this.isOnline ? this.orderForm.name = order.sellerId : this.orderForm.name = order.sellerName
        this.$set(this.orderForm, 'buyerPaymentTerm', order.paymentTerm)
        this.$set(this.orderForm, 'buyerCreditDay', order.creditDay)
        this.$set(this.orderForm, 'buyerPaymentTermDate', order.paymentTermDate)
        this.$set(this.orderForm, 'remark', order.sellerRemark)
        this.linkedSalesTermContract = order.termContract
      } else {
        this.orderForm.purchaseProducts = order.products

        if (this.orderForm.purchaseProducts?.length > 0) {
          this.orderForm.purchaseProducts.forEach(product => {
            product.sysProductSpecificationId ??= this.defaultSysProductSpecId
          })
        }else{
          this.orderForm.purchaseProducts = this.orderFormProduct;
          this.orderForm.purchaseProducts[0] = this.defaultSysProductSpecId
        }
        this.orderForm.purchaseOtherCosts = order.otherCosts
        this.isOnline ? this.orderForm.name = order.buyerId : this.orderForm.name = order.buyerName
        this.$set(this.orderForm, 'sellerPaymentTerm', order.paymentTerm)
        this.$set(this.orderForm, 'sellerCreditDay', order.creditDay)
        this.$set(this.orderForm, 'sellerPaymentTermDate', order.paymentTermDate)
        this.$set(this.orderForm, 'remark', order.buyerRemark)
        this.linkedPurchaseTermContract = order.termContract
      }
      this.$set(this.orderForm, 'dateRange', [order.stemStartDate, order.stemEndDate])
      if ((!order.vesselName || !order.vesselShipId) && (order.orderType === this.ORDER_TYPE.DELIVERED || order.orderType === this.ORDER_TYPE.BARGING_NOMINATION)) {
        this.isVesselTBC = true
      }

      if (order.orderType === this.ORDER_TYPE.DELIVERED && (this.currentView === this.ORDER_VIEW.EDIT_ORDER || this.currentView === this.ORDER_VIEW.VIEW_ORDER) ) {
        this.vesselSearchKey = order.vesselName
        const locationLevel = this.getLocationLevel(order.bunkerLocation)
        this.$set(this.orderForm, 'locationLevel', locationLevel)
        this.$set(this.orderForm, 'bunkerLocation', order.bunkerLocation)
        this.$set(this.orderForm, 'bargeName', order.bunkerBargeName)
        this.$set(this.orderForm, 'pricingDate', order.pricingDate)
        let isVictoryCust = order.territory || order.isVictoryCust;
        if (this.currentView == this.ORDER_VIEW.CREATE_ORDER ||  this.orderForm.buyerOrderStatus == ORDER_STATUS.DRAFT || this.orderForm.sellerOrderStatus == ORDER_STATUS.DRAFT ) {
          isVictoryCust = this.isShowVictoryFeats

        }
        this.$set(this.orderForm, 'isVictoryCust', isVictoryCust)
        this.$set(this.orderForm, 'supplyType', order.supplyType)
        this.nextPortDisabled = order.supplyType === 'D'

      }
      this.isOrderFormLoading = false
    },
    getLocationLevel(locationLevel2){
      if (!locationLevel2) return null;
      let locationLevel;
      for (let i = 0; i < this.locationList.length; i++) {
        if ( this.locationList[i].children.length > 0 ) {
          const childItem = this.locationList[i].children.find(c=>c.name == locationLevel2);
          if (childItem) {
            locationLevel = [this.locationList[i].name,locationLevel2]
            break;
          }
        }
      }
      this.orderForm.locationLevel =locationLevel
      return locationLevel;
    },
    handleNominationDateChange () {
      if (this.tradeType === ORDER_TRADE_TYPE.SALES || this.tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) {
        this.orderForm.salesProducts.forEach(p => {
          if (p.pricePeriod === 'Date of Nomination (DON)') {
            p.priceStartDate = this.orderForm.nominationDate
            p.priceEndDate = this.orderForm.nominationDate
          }
        })
      }
      if (this.tradeType === ORDER_TRADE_TYPE.PURCHASE || this.tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) {
        this.orderForm.purchaseProducts.forEach(p => {
          if (p.pricePeriod === 'Date of Nomination (DON)') {
            p.priceStartDate = this.orderForm.nominationDate
            p.priceEndDate = this.orderForm.nominationDate
          }
        })
      }
    },
    nominationDatePickerOption () {
      const disabledDate = (date) => {
        const orderDate = this.$moment(this.orderForm.orderDate).format('YYYY-MM-DD')
        date = this.$moment(date).format('YYYY-MM-DD')
        return this.$moment(orderDate).isAfter(date)
      }
      return { disabledDate }
    },
    handleTBCChange () {
      this.isNewVessel = false
      this.orderForm.vesselName = null
      this.orderForm.vesselImoNo = null
      this.orderForm.vesselShipId = null
      this.orderForm.vesselLicenseNo = null
      this.$refs.orderForm.clearValidate('vesselName')
      this.$refs.orderForm.clearValidate('vesselImoNo')
      this.$refs.orderForm.clearValidate('vesselShipId')
      this.$refs.orderForm.clearValidate('vesselLicenseNo')
    },
    handlePriceInfoChange (val, index, name, parent) {
      if (parent === 'OrderFormSalesProduct') {
        this.orderForm.salesProducts[index][name] = val
      } else if (parent === 'OrderFormPurchaseProduct') {
        this.orderForm.purchaseProducts[index][name] = val
      }
    },
    handleReferencePriceChange (val, index, parent) {
      if (parent === 'OrderFormSalesProduct') {
        this.orderForm.salesProducts[index].referencePrice = val
      } else if (parent === 'OrderFormSalesProduct') {
        this.orderForm.purchaseProducts[index].referencePrice = val
      }
    },
    orderStemDateRangePickerOptions () {
      const disabledDate = date => {
        if (this.linkedSalesTermContract && this.linkedPurchaseTermContract) {
          let tempStartDate, tempEndDate
          new Date(this.linkedSalesTermContract.contractStartDate) > new Date(this.linkedPurchaseTermContract.contractStartDate) ? tempStartDate = this.linkedSalesTermContract.contractStartDate
            : tempStartDate = this.linkedPurchaseTermContract.contractStartDate
          new Date(this.linkedSalesTermContract.contractEndDate) < new Date(this.linkedPurchaseTermContract.contractEndDate) ? tempEndDate = this.linkedSalesTermContract.contractEndDate
            : tempEndDate = this.linkedPurchaseTermContract.contractEndDate
          return (date < new Date(tempStartDate) || date > new Date(tempEndDate))
        } else if (this.linkedSalesTermContract) {
          return (date < new Date(this.linkedSalesTermContract.contractStartDate) || date > new Date(this.linkedSalesTermContract.contractEndDate))
        } else if (this.linkedPurchaseTermContract) {
          return (date < new Date(this.linkedPurchaseTermContract.contractStartDate) || date > new Date(this.linkedPurchaseTermContract.contractEndDate))
        } else {
          return null
        }
      }
      return { disabledDate }
    },
    setHolidays () {
      const currentDate = new Date()
      let startDate = null
      let endDate = null
      if (this.orderForm.salesProducts) {
        this.orderForm.salesProducts.forEach(p => {
          if (p.priceStartDate && p.priceEndDate) {
            const priceStartDate = new Date(p.priceStartDate)
            const priceEndDate = new Date(p.priceEndDate)
            if (startDate || startDate > priceStartDate) {
              startDate = priceStartDate
            }
            if (endDate || endDate < priceEndDate) {
              endDate = priceEndDate
            }
          }
        })
      }
      if (this.orderForm.purchaseProducts) {
        this.orderForm.purchaseProducts.forEach(p => {
          if (p.priceStartDate && p.priceEndDate) {
            const priceStartDate = new Date(p.priceStartDate)
            const priceEndDate = new Date(p.priceEndDate)
            if (startDate || startDate > priceStartDate) {
              startDate = priceStartDate
            }
            if (endDate || endDate < priceEndDate) {
              endDate = priceEndDate
            }
          }
        })
      }
      if (startDate) {
        startDate = new Date(startDate.getFullYear() - 2, 0, 1)
      } else {
        startDate = new Date(currentDate.getFullYear() - 2, 0, 1)
      }
      if (endDate) {
        endDate = new Date(endDate.getFullYear(), 11, 31)
      } else {
        endDate = new Date(currentDate.getFullYear() + 2, 11, 31)
      }
      this.getCalendarByRange(startDate, endDate).then(data => {
        data.forEach(d => {
          this.holidayMap.set(d.calendarDate, d)
        })
      })
      if (!this.orderForm.salesProducts && !this.orderForm.purchaseProducts) {
        const startDate = new Date(currentDate.getFullYear() - 2, 0, 1)
        const endDate = new Date(currentDate.getFullYear() + 2, 11, 31)
        this.getCalendarByRange(startDate, endDate).then(data => {
          data.forEach(d => {
            this.holidayMap.set(d.calendarDate, d)
          })
        })
      }
    },
    async getCalendarByRange (startDate, endDate) {
      try {
        const res = await this.$request.get({
          url: `${this.$apis.calendar}/range?startDate=${startDate.toISOString()}&endDate=${endDate.toISOString()}`
        })
        if (res?.code === 1000) {
          return res.data.filter(d => d.fullDay)
        }
      } catch (e) {}
    },
    handleMinQtyChange (index, field) {
      if (field === 'BUYER') {
        if (!this.orderForm.purchaseProducts[index].maximumQuantity) {
          this.orderForm.purchaseProducts[index].finalQuantity = this.orderForm.purchaseProducts[index].minimumQuantity
        } else {
          this.$refs.orderForm.validateField(`purchaseProducts.${index}.maximumQuantity`)
        }
        this.$refs.orderForm.validateField(`purchaseProducts.${index}.finalQuantity`)
      } else if (field === 'SELLER') {
        if (!this.orderForm.salesProducts[index].maximumQuantity) {
          this.orderForm.salesProducts[index].finalQuantity = this.orderForm.salesProducts[index].minimumQuantity
        } else {
          this.$refs.orderForm.validateField(`salesProducts.${index}.maximumQuantity`)
        }
        this.$refs.orderForm.validateField(`salesProducts.${index}.finalQuantity`)
      }
    },
    handleMaxQtyChange (index, field) {
      if (field === 'BUYER') {
        if (this.orderForm.purchaseProducts[index].maximumQuantity) {
          this.orderForm.purchaseProducts[index].finalQuantity = null
        } else {
          this.orderForm.purchaseProducts[index].finalQuantity = this.orderForm.purchaseProducts[index].minimumQuantity
        }
        this.$refs.orderForm.validateField(`purchaseProducts.${index}.minimumQuantity`)
        this.$refs.orderForm.validateField(`purchaseProducts.${index}.finalQuantity`)
      } else {
        if (this.orderForm.salesProducts[index].maximumQuantity) {
          this.orderForm.salesProducts[index].finalQuantity = null
        } else {
          this.orderForm.salesProducts[index].finalQuantity = this.orderForm.salesProducts[index].minimumQuantity
        }
        this.$refs.orderForm.validateField(`salesProducts.${index}.minimumQuantity`)
        this.$refs.orderForm.validateField(`salesProducts.${index}.finalQuantity`)
      }
    },
    handleFinalQtyChange (index, field) {
      if (field === 'BUYER') {
        this.$refs.orderForm.validateField(`purchaseProducts.${index}.minimumQuantity`)
        this.$refs.orderForm.validateField(`purchaseProducts.${index}.maximumQuantity`)
      } else {
        this.$refs.orderForm.validateField(`salesProducts.${index}.minimumQuantity`)
        this.$refs.orderForm.validateField(`salesProducts.${index}.maximumQuantity`)
      }
    },
    handleProductNameVisibleChange (field) {
      this.tempProductList = field === 'SELLER' ? this.$_.cloneDeep(this.orderForm.salesProducts) : this.$_.cloneDeep(this.orderForm.purchaseProducts)
    },
    handleProductNameChange (val, index, field) {
      // For PURCHASE_AND_SALES: Add sales product and populate product name
      if (this.tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) {
        if (!this.orderForm.salesProducts.find(d => d.sysProductId === val)) {
          if (this.orderForm.salesProducts.length === 1 && !this.orderForm.salesProducts[0].sysProductId) {
            this.orderForm.salesProducts[0].sysProductId = val
          } else {
            this.addProduct('SELLER')
            this.orderForm.salesProducts[this.orderForm.salesProducts.length - 1].sysProductId = val
          }
        }
      }
      this.checkProductNameAndSpecsChange('sysProductId', val, index, field)
    },
    // Cannot edit product name or product specs if there is no other productname-productspecs pair in order form that exists in job
    checkProductNameAndSpecsChange (key, val, index, field) {
      const products = field === 'SELLER' ? this.orderForm.salesProducts : this.orderForm.purchaseProducts
      const filteredList = this.deliveredProductTableData.filter(d => d.sysProductId === this.tempProductList[index].sysProductId).filter(d => d.sysProductSpecificationId === this.tempProductList[index].sysProductSpecificationId)
      // If productname-productspecs pair exists n job, check if there exists another same productname-productspecs pair
      if (filteredList.length > 0) {
        const list = this.$_.cloneDeep(this.tempProductList)
        list[index][key] = val
        const filteredList = list.filter(d => d.sysProductId === this.tempProductList[index].sysProductId).filter(d => d.sysProductSpecificationId === this.tempProductList[index].sysProductSpecificationId)
        if (filteredList.length < 1) {
          products[index][key] = this.tempProductList[index][key]
          this.$message.error('Product Name and Product Specs pair exists in job, requires at least one pair in product.')
          return false
        }
      }
      const i = this.tempProductList.findIndex(d => d.sysProductId === products[index].sysProductId && d.sysProductSpecificationId === products[index].sysProductSpecificationId)
      if (i !== -1) {
        field === 'SELLER' ? this.orderForm.salesProducts[index].physicalSupplierSysOrganizationId = this.tempProductList[i].physicalSupplierSysOrganizationId
          : this.orderForm.purchaseProducts[index].physicalSupplierSysOrganizationId = this.tempProductList[i].physicalSupplierSysOrganizationId
      }
      this.getOtherCostProDropDownList(true, this.orderForm.purchaseProducts)
      this.getOtherCostProDropDownList(false, this.orderForm.salesProducts)
    },
    handleProductSpecsVisibleChange (field) {
      this.tempProductList = field === 'SELLER' ? this.$_.cloneDeep(this.orderForm.salesProducts) : this.$_.cloneDeep(this.orderForm.purchaseProducts)
    },
    handleProductSpecsChange (val, index, field) {
      this.checkProductNameAndSpecsChange('sysProductSpecificationId', val, index, field)
    },
    handlePhysicalSupplierBdnChange (val, index, field) {
      if (this.orderForm.orderType === this.ORDER_TYPE.DELIVERED) {
        const products = field === 'SELLER' ? this.orderForm.salesProducts : this.orderForm.purchaseProducts
        const product = products[index]
        products.forEach(p => {
          if (p.sysProductId === product.sysProductId && p.sysProductSpecificationId === product.sysProductSpecificationId) {
            p.physicalSupplierSysOrganizationId = val
          }
        })
      }
    },
    handlePaymentTermChange (val, isPurchaseInfo) {
      if (isPurchaseInfo) {
        if (val === 'CASH_IN_ADVANCE' || val === 'SPECIFIC_DATE') {
          this.orderForm.buyerCreditDay = 0
        }
        this.orderForm.buyerPaymentTermDate = null
      } else {
        if (val === 'CASH_IN_ADVANCE' || val === 'SPECIFIC_DATE') {
          this.orderForm.sellerCreditDay = 0
        }
        this.orderForm.sellerPaymentTermDate = null
      }
    },
    handleLinkTermContractDialogClose () {
      this.dialogLinkTermContract = false
    },
    handlePriceTypeSelect (val, row, field, index) {
      if (val === ORDER_PRICE_TYPE.FORMULA) {
        field === 'BUYER' ? this.$refs.purchaseProductTable.toggleRowExpansion(row, true) : this.$refs.salesProductTable.toggleRowExpansion(row, true)
      } else {
        if (field === 'BUYER') {
          this.$refs.purchaseProductTable.toggleRowExpansion(row, false)
          for (const key in this.productPrice) {
            this.orderForm.purchaseProducts[index][key] = null
          }
        } else {
          this.$refs.salesProductTable.toggleRowExpansion(row, false)
          for (const key in this.productPrice) {
            this.orderForm.salesProducts[index][key] = null
          }
        }
        this.resetProductPriceWatcher = !this.resetProductPriceWatcher
      }
      if (field === 'BUYER') {
        this.orderForm.purchaseProducts[index].priceRateTypeMetric = 'MID'
        this.orderForm.purchaseProducts[index].priceConversionRate = 1
        this.orderForm.purchaseProducts[index].priceUnitMetric = 'MT'
      } else {
        this.orderForm.salesProducts[index].priceRateTypeMetric = 'MID'
        this.orderForm.salesProducts[index].priceConversionRate = 1
        this.orderForm.salesProducts[index].priceUnitMetric = 'MT'
      }
    },
    handleLinkTermContractConfirm (linkedContract) {
      this.getTermContractById(linkedContract.id).then(termContract => {
        this.orderForm.termsAndConditions = termContract.termsAndConditions
        this.orderForm.port = termContract.port
        this.$set(this.orderForm, 'dateRange', null)
        if (this.isLinkedSalesTermContract) {
          this.orderForm.sellerReferenceNo = termContract.sellerReferenceNo
          this.linkedSalesTermContract = this.$_.cloneDeep(termContract)
          this.handleCompanyNameSelect(termContract.buyerSysOrganizationId, 'BUYER')
          this.orderForm.buyerSysOrganizationId = termContract.buyerSysOrganizationId
          this.orderForm.buyerId = termContract.buyerId
          this.orderForm.buyerEmail = termContract.buyerEmail
          this.orderForm.salesProducts = []
          this.orderForm.salesOtherCosts = []
          termContract.products.forEach((p, index) => {
            delete p.id
            this.addProduct('SELLER')
            for (const property in this.orderFormProduct) {
              this.$set(this.orderForm.salesProducts[index], property, termContract.products[index][property])
            }
          })
          this.getOtherCostProDropDownList(false, this.orderForm.salesProducts)
        } else {
          this.orderForm.buyerReferenceNo = termContract.buyerReferenceNo
          this.linkedPurchaseTermContract = this.$_.cloneDeep(termContract)
          this.handleCompanyNameSelect(termContract.sellerSysOrganizationId, 'SELLER')
          this.orderForm.sellerSysOrganizationId = termContract.sellerSysOrganizationId
          this.orderForm.sellerId = termContract.sellerId
          this.orderForm.sellerEmail = termContract.sellerEmail
          this.orderForm.purchaseProducts = []
          this.orderForm.purchaseOtherCosts = []
          termContract.products.forEach((p, index) => {
            delete p.id
            this.addProduct('BUYER')
            for (const property in this.orderFormProduct) {
              this.$set(this.orderForm.purchaseProducts[index], property, termContract.products[index][property])
            }
          })
          this.getOtherCostProDropDownList(this.$route.path, this.orderForm.purchaseProducts)
        }
        this.dialogLinkTermContract = false
      })
    },
    getTermContractById (id) {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.termContract}/${id}`
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          }
        })
      })
    },
    handleOrderViewChange (view) {
      /* get the newest vessel list for edit */
      if (view === 'EDIT_ORDER' && [ORDER_TYPE.DELIVERED, ORDER_TYPE.BARGING_NOMINATION].includes(this.orderForm.orderType)) { this.$store.dispatch('getVesselList') }
      if (view === 'EDIT_ORDER' && this.orderForm.orderType !== ORDER_TYPE.EX_WHARF && this.tradeType === ORDER_TRADE_TYPE.PURCHASE && this.orderForm.offlineBuyerSeller !== 'SELLER' && this.orderForm.buyerOrderStatus !== ORDER_STATUS.DRAFT) {
        this.$emit('handleOrderViewChange', 'VIEW_ORDER')
        this.isEditPurchaseOrder = true

        this.$refs?.productPriceDiv?.scrollIntoView({ behavior: 'smooth' })
        this.currentView = this.ORDER_VIEW.VIEW_ORDER
      } else {
        this.$emit('handleOrderViewChange', view)
        this.currentView = view
      }
    },
    handleCancelEditClick () {
      const title = 'Confirm Discard Changes'
      const body = `Are you sure you want to discard all changes made to this ${this.orderFormTabActive === 'ORDER_INFO' ? 'order' : 'commission'}?`
      this.$confirm(body, title, {
        cancelButtonText: 'Close',
        confirmButtonText: 'Ok',
        type: 'warning'
      }).then(() => {
        this.handleOrderViewChange('VIEW_ORDER')
        if (this.orderFormTabActive !== 'COMMISSION') {
          this.isEditPurchaseOrder = false
          /* need to manually reset these fields */
          if (this.tradeType === ORDER_TRADE_TYPE.SALES) {
            this.linkedSalesTermContract = this.$_.cloneDeep(this.tempOrder.termContract)
            this.handleCompanyNameSelect(this.tempOrder.buyerSysOrganizationId, 'BUYER')
            this.orderForm.remark = this.$_.cloneDeep(this.tempOrder.sellerRemark)
          } else {
            this.linkedPurchaseTermContract = this.$_.cloneDeep(this.tempOrder.termContract)
            this.handleCompanyNameSelect(this.tempOrder.sellerSysOrganizationId, 'SELLER')
            this.orderForm.remark = this.$_.cloneDeep(this.tempOrder.buyerRemark)
          }
          this.orderForm.thirdParties = this.$_.cloneDeep(this.tempOrder.thirdParties)
          this.$set(this.orderForm.thirdParties[0], 'contact', this.tempOrder.thirdParties[0].contact ? this.$_.cloneDeep(this.tempOrder.thirdParties[0].contact) : '+65')
          this.$set(this.orderForm.thirdParties[1], 'contact', this.tempOrder.thirdParties[1].contact ? this.$_.cloneDeep(this.tempOrder.thirdParties[1].contact) : '+65')
          if ((!this.tempOrder.vesselName || !this.tempOrder.vesselShipId) && (this.tempOrder.orderType === this.ORDER_TYPE.DELIVERED || this.tempOrder.orderType === this.ORDER_TYPE.BARGING_NOMINATION)) {
            this.isVesselTBC = true
            this.isNewVessel = false
            this.orderForm.vesselName = null
            this.orderForm.vesselImoNo = null
            this.orderForm.vesselShipId = null
            this.orderForm.vesselLicenseNo = null
          }
          this.$refs.orderForm.resetFields()
          /* need to set products after resetFields */
          if (this.tradeType === ORDER_TRADE_TYPE.SALES) {
            this.orderForm.salesProducts = this.$_.cloneDeep(this.tempOrder.products)
            this.orderForm.salesOtherCosts = this.$_.cloneDeep(this.tempOrder.otherCosts)
          } else {
            this.orderForm.purchaseProducts = this.$_.cloneDeep(this.tempOrder.products)
            this.orderForm.purchaseOtherCosts = this.$_.cloneDeep(this.tempOrder.otherCosts)
          }
        } else {
          this.resetOrderComm = !this.resetOrderComm
          this.$refs.orderCommission.$refs.orderCommissionForm.resetFields()
        }
      }).catch(() => {})
    },
    handleOrderFormDropdownClick (val) {
      if (val === 'VERIFY') {
        this.dialogVerifyOrder = true
      } else {
        const title = `${capitalizeFirstLetter(val)} Order`
        const body = `Are you sure you want to ${val.toLowerCase()} this order?`
        this.$confirm(body, title, {
          confirmButtonText: `${capitalizeFirstLetter(val)}`,
          cancelButtonText: 'Close',
          type: 'warning'
        }).then(() => {
          switch (val) {
            case 'CONFIRM':
              this.handleConfirmOrderVerifyCA()
              break
            case 'DELETE':
              this.handleDeleteOrder()
              break
            case 'CANCEL':
              this.handleCancelOrder()
              break
          }
        })
      }
    },
    emailValidation (rule, value, callback) {
      if (!value) {
        callback(new Error('This field is required'))
      } else {
        const regex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
        if (!regex.test(value)) {
          callback(new Error('Please enter a valid email address.'))
        } else {
          callback()
        }
      }
    },
    emailNotReqValidation (rule, value, callback) {
      if (value) {
        const regex = /^[A-Za-z\d]+([-_.][A-Za-z\d]+)*@([A-Za-z\d]+[-.])+[A-Za-z\d]{2,4}$/
        if (!regex.test(value)) {
          callback(new Error('Please enter a valid email address.'))
        } else {
          callback()
        }
      }
      callback()
    },
    reqGreaterThanZeroValidation (rule, value, callback) {
      if ((value === 0)) {
        callback(new Error('Value must be greater than 0'))
      } else if (!value) {
        callback(new Error('This field is required'))
      } else {
        callback()
      }
    },
    finalQtyValidation (rule, value, callback) {

      const i = rule.field.split('.')[1]
      let isBuyer = false
      rule.field.split('.')[0] === 'purchaseProducts' ? isBuyer = true : isBuyer = false
      if (!((isBuyer && !this.orderForm.purchaseProducts[i].finalQuantity) || (!isBuyer && !this.orderForm.salesProducts[i].finalQuantity))) {
        if (isBuyer && this.orderForm.purchaseProducts[i].minimumQuantity > this.orderForm.purchaseProducts[i].finalQuantity) {
          callback(new Error('Value must be greater or equal to Min Qty'))
        } else if (!isBuyer && this.orderForm.salesProducts[i].minimumQuantity > this.orderForm.salesProducts[i].finalQuantity) {
          callback(new Error('Value must be greater or equal to Min Qty'))
        } else if (isBuyer && this.orderForm.purchaseProducts[i].maximumQuantity && this.orderForm.purchaseProducts[i].maximumQuantity < this.orderForm.purchaseProducts[i].finalQuantity) {
          callback(new Error('Value must be lesser or equal to Max Qty'))
        } else if (!isBuyer && this.orderForm.salesProducts[i].maximumQuantity && this.orderForm.salesProducts[i].maximumQuantity < this.orderForm.salesProducts[i].finalQuantity) {
          callback(new Error('Value must be lesser or equal to Max Qty'))
        }
      }
      callback()
    },
    maxQtyValidation (rule, value, callback) {

      const i = rule.field.split('.')[1]
      let isBuyer = false
      rule.field.split('.')[0] === 'purchaseProducts' ? isBuyer = true : isBuyer = false
      if (!((isBuyer && !this.orderForm.purchaseProducts[i].maximumQuantity) || (!isBuyer && !this.orderForm.salesProducts[i].maximumQuantity))) {
        if (isBuyer) {
          if (this.orderForm.purchaseProducts[i].minimumQuantity && this.orderForm.purchaseProducts[i].minimumQuantity > this.orderForm.purchaseProducts[i].maximumQuantity) {
            callback(new Error('Value must be greater or equal to Min Qty'))
          } else if (this.orderForm.purchaseProducts[i].finalQuantity && this.orderForm.purchaseProducts[i].finalQuantity > this.orderForm.purchaseProducts[i].maximumQuantity) {
            callback(new Error('Value must be greater or equal to Nominated Qty'))
          } else {
            callback()
          }
        } else {
          if (this.orderForm.salesProducts[i].minimumQuantity && this.orderForm.salesProducts[i].minimumQuantity > this.orderForm.salesProducts[i].maximumQuantity) {
            callback(new Error('Value must be greater or equal to Min Qty'))
          } else if (this.orderForm.salesProducts[i].finalQuantity && this.orderForm.salesProducts[i].finalQuantity > this.orderForm.salesProducts[i].maximumQuantity) {
            callback(new Error('Value must be greater or equal to Nominated Qty'))
          } else {
            callback()
          }
        }
      } else if(rule.required) {
        callback(new Error('This field is required'))
      }else {
        callback()
      }
    },
    minQtyValidation (rule, value, callback) {

      const i = rule.field.split('.')[1]
      let isBuyer = false
      rule.field.split('.')[0] === 'purchaseProducts' ? isBuyer = true : isBuyer = false
      if (!((isBuyer && !this.orderForm.purchaseProducts[i].minimumQuantity) || (!isBuyer && !this.orderForm.salesProducts[i].minimumQuantity))) {
        if (isBuyer) {
          if (this.orderForm.purchaseProducts[i].maximumQuantity && this.orderForm.purchaseProducts[i].maximumQuantity < this.orderForm.purchaseProducts[i].minimumQuantity) {
            callback(new Error('Value must be lesser or equal to Max Qty'))
          } else if (this.orderForm.purchaseProducts[i].finalQuantity && this.orderForm.purchaseProducts[i].finalQuantity < this.orderForm.purchaseProducts[i].minimumQuantity) {
            callback(new Error('Value must be lesser or equal to Nominated Qty'))
          } else {
            callback()
          }
        } else {
          if (this.orderForm.salesProducts[i].maximumQuantity && this.orderForm.salesProducts[i].maximumQuantity < this.orderForm.salesProducts[i].minimumQuantity) {
            callback(new Error('Value must be lesser or equal to Max Qty'))
          } else if (this.orderForm.salesProducts[i].finalQuantity && this.orderForm.salesProducts[i].finalQuantity < this.orderForm.salesProducts[i].minimumQuantity) {
            callback(new Error('Value must be lesser or equal to Nominated Qty'))
          } else {
            callback()
          }
        }
      } else if(rule.required) {
        callback(new Error('This field is required'))
      } else {
        callback()
      }
    },

    addProduct (field) {
      const product = this.$_.cloneDeep(this.orderFormProduct)
      if (this.orderForm.orderType !== this.ORDER_TYPE.EX_WHARF && this.myCompany.licenseType) {
        product.physicalSupplierSysOrganizationId = this.physicalSupplierList.find(d => d.id === this.myCompany.id).id
      }
      /// for victory funcs ---start
      if (this.orderForm.orderType == this.ORDER_TYPE.DELIVERED && this.isShowVictoryFeats && this.tradeType == this.ORDER_TRADE_TYPE.PURCHASE) {
        product.physicalSupplierSysOrganizationId = this.orderForm?.sellerSysOrganizationId
      }
      /// for victory funcs ---end
      field === 'BUYER' ? this.orderForm.purchaseProducts.push(product) : this.orderForm.salesProducts.push(product)
    },
    deleteAlert (isDeleteProduct, rowData, index, field) {
      const otherCostList = field === 'BUYER' ? this.orderForm.purchaseOtherCosts : this.orderForm.salesOtherCosts
      const linkedOtherCostList = (otherCostList.filter(othercost => othercost.orderProductId === (rowData?.id ?? rowData?.uuid))) || []
      const title = isDeleteProduct ? `${linkedOtherCostList.length > 0 ? `This product is connected to ${linkedOtherCostList.length} other products. ` : ''}Are you sure to delete?` : 'Are you sure to delete this other cost item ?'
      this.$confirm(title, {
        type: 'warning',
        showCancelButton: true,
        cancelButtonText: 'Cancel',
        confirmButtonText: 'Delete'
      }).then(() => {
        const removedOrderProductId = linkedOtherCostList.length > 0 && linkedOtherCostList[0].orderProductId
        if (isDeleteProduct) {
          this.removeProduct(index, field)
          this.removeOtherCost(index, field, removedOrderProductId, linkedOtherCostList)
        } else {
          this.removeOtherCost(index, field, null)
        }
      }).catch(() => {
      })
    },
    removeProduct (index, field) {
      field === 'BUYER' ? this.orderForm.purchaseProducts.splice(index, 1) : this.orderForm.salesProducts.splice(index, 1)
      this.getOtherCostProDropDownList(field === 'BUYER', field === 'BUYER' ? this.orderForm.purchaseProducts : this.orderForm.salesProducts)
    },
    addOtherCost (field) {
      const orderFormOtherCost = {
        description: null,
        orderProductId: null,
        quantity: 0,
        unitPrice: 0,
        cost: 0,
        currency: 'USD'
      }
      const otherCost = this.$_.cloneDeep(orderFormOtherCost)
      field === 'BUYER' ? this.orderForm.purchaseOtherCosts.push(otherCost) : this.orderForm.salesOtherCosts.push(otherCost)
    },
    removeOtherCost (index, field, removedOrderProductId) {
      const isBuyer = field === 'BUYER'
      if (removedOrderProductId) {
        const otherCostList = this.$_.cloneDeep(isBuyer ? this.orderForm.purchaseOtherCosts : this.orderForm.salesOtherCosts)
        otherCostList.forEach(othercost => {
          if (othercost.orderProductId === removedOrderProductId) {
            for (var i = 0; i < (isBuyer ? this.orderForm.purchaseOtherCosts : this.orderForm.salesOtherCosts).length; i++) {
              if ((isBuyer ? this.orderForm.purchaseOtherCosts : this.orderForm.salesOtherCosts)[i].orderProductId === removedOrderProductId) {
                (isBuyer ? this.orderForm.purchaseOtherCosts : this.orderForm.salesOtherCosts).splice(i, 1)
              }
            }
          }
        })
      } else {
        field === 'BUYER' ? this.orderForm.purchaseOtherCosts.splice(index, 1) : this.orderForm.salesOtherCosts.splice(index, 1)
      }
    },
    calculateOtherCostAmount (idx, isBuyer) {
      if (isBuyer) {
        this.orderForm.purchaseOtherCosts[idx].cost = this.orderForm.purchaseOtherCosts[idx].quantity * this.orderForm.purchaseOtherCosts[idx].unitPrice
      } else {
        this.orderForm.salesOtherCosts[idx].cost = this.orderForm.salesOtherCosts[idx].quantity * this.orderForm.salesOtherCosts[idx].unitPrice
      }
    },
    getOtherCostProDropDownList (isBuyer, list) {
      const proItemlist = list
      const proDropDownList = []
      proItemlist.forEach(item => {
        if (item.sysProductId) {
          if (!item.uuid && !item.id) { item.uuid = getUuiD(5) }
          const displayName = `${this.productList.find(pro => pro.id === item.sysProductId)?.name ?? '-'}(${this.productSpecificationList.find(spsc => spsc.id === item.sysProductSpecificationId)?.name?.slice(-4) ?? '-'} SPECS)`
          proDropDownList.push({
            displayName,
            orderProductId: item?.id ?? item?.uuid
          })
        }
      })
      if (isBuyer) {
        this.purchaseOtherCostProDropDownList = proDropDownList
      } else {
        this.salesOtherCostProDropDownList = proDropDownList
      }
    },
    getOtherCostList (productList, otherCostList) {
      const _otherCostList = otherCostList
      _otherCostList.forEach(othercost => {
        productList.forEach((product, index) => {
          if (!product.id && othercost.orderProductId === product?.uuid) {
            othercost.orderProductIndex = index
            delete othercost.orderProductId
            // delete product.uuid
          }
        })
        return othercost
      })
      return _otherCostList
    },
    async getAllMethodology () {
      const getMethodology = (methodologyType) => {
        return new Promise(resolve => {
          this.$request.get({
            url: `/methodologies/companies/${this.myCompany.id}/bunkerwireType/${methodologyType}`
          }).then(res => {
            if (res?.code === 1000) {
              const methodologies = res.data
              resolve({ methodologies })
            }
          })
        })
      }
      const promises = []
      this.ORDER_PRICE_RATE_TYPE_OPTIONS.forEach(t => {
        promises.push(getMethodology(t.value))
      })
      const methodologyInfoList = await Promise.all(promises)
      methodologyInfoList.forEach(m => {
        m.methodologies?.forEach(i => {
          this.priceMethodologyList.push(i)
        })
      })
    },
    handleOrderTableRightMenuClick (parent) {
      if (parent) {
        parent === 'LinkedSalesOrder' ? this.linkedSalesTermContract = null : this.linkedPurchaseTermContract = null
      }
    },
    handleCloseOrderForm (action) {
      this.$emit('handleCloseOrderForm', action)
    },
    handleCompanyNameSelect (companyId, field,isFromSummary) {
      this.creditAssociation = this.creditAssociationList.find(item => item.counterpartySysOrganizationId === companyId) || {}
      if (field === 'BUYER') {
        this.isSeller = false
        this.orderForm.buyerId = null
        this.orderForm.buyerEmail = null
        this.orderForm.buyerCreditDay = this.creditAssociation.creditDays
        this.orderForm.buyerSysOrganizationId = companyId
        if (this.linkedSalesTermContract && this.linkedSalesTermContract.termContractType === 'TIME_CHARTER') {
          this.orderForm.buyerPaymentTerm = null
          this.orderForm.buyerCreditDay = null
          this.orderForm.buyerPaymentTermDate = null
        } else {
          this.creditAssociation.creditDays === 0 ? this.orderForm.buyerPaymentTerm = 'CASH_IN_ADVANCE' : this.orderForm.buyerPaymentTerm = 'CREDIT_TERMS'
        }

      } else {
        this.isSeller = true
        this.orderForm.sellerId = null
        this.orderForm.sellerEmail = null
        this.orderForm.sellerCreditDay = this.creditAssociation.creditDays
        if (this.linkedPurchaseTermContract && this.linkedPurchaseTermContract.termContractType === 'TIME_CHARTER') {
          this.orderForm.sellerPaymentTerm = null
          this.orderForm.sellerCreditDay = null
          this.orderForm.sellerPaymentTermDate = null
        } else {
          this.creditAssociation.creditDays === 0 ? this.orderForm.sellerPaymentTerm = 'CASH_IN_ADVANCE' : this.orderForm.sellerPaymentTerm = 'CREDIT_TERMS'
        }

        // initial the today pricing for every company(seller) change
        this.todayPricing = '-'
        this.setSupplierProductPrice()
      }

      this.chooseDate(this.orderForm.pricingDate)

      this.getUserListByCompanyId(companyId, this.creditAssociation.isOnlineCounterparty).then(userList => {
        field === 'BUYER' ? this.buyerCustomerList = userList : this.sellerCustomerList = userList
        /* for victory funcs  ---start */
        if ((isFromSummary || this.sellerHasVictoryFeats) && userList.length > 0) {
        const _userList = userList.filter(user => user?.name && user?.email && user?.name.trim().length > 0)
          const personInCharge = _userList[0]
            if (field === 'BUYER') {
              this.orderForm.buyerId = personInCharge?.id
              this.orderForm.buyerEmail = personInCharge?.email
            } else {
              this.orderForm.sellerId = personInCharge?.id
              this.orderForm.sellerEmail = personInCharge?.email
            }
        }
        /* for victory funcs  ---end */
      })
       /* for victory funcs  ---start */

      if (this.orderForm.orderType == this.ORDER_TYPE.DELIVERED && this.isShowVictoryFeats && this.tradeType == this.ORDER_TRADE_TYPE.PURCHASE && field ==='SELLER' ) {
        const isPhysicalSupplier = !!this.physicalSupplierList.find(org=> org.id == companyId);
        this.orderForm.purchaseProducts.forEach(product =>  product.physicalSupplierSysOrganizationId = isPhysicalSupplier?companyId: null);
      }
      // product show LSMGO DMA 0.1%S MAX when the group code is ADHOC.
      this.setForVictoryFeats()
      /* for victory funcs  ---end */

    },
    chooseDate(pricingDate) {

      if (this.orderType === this.ORDER_TYPE.DELIVERED && this.creditAssociation) {

        if (this.tradeType === ORDER_TRADE_TYPE.PURCHASE) {

          this.sellerHasVictoryFeats = this.creditAssociation.isPricingTierEnabled

          if ( this.sellerHasVictoryFeats) {
            this.creditLimit = this.creditAssociation?.creditLimit??'-'
            if (pricingDate && this.currentCompany.pricingTier) {
              this.getTodayPricing(pricingDate, this.currentCompany.pricingTier,)
            }
          }
        } else {
          this.creditLimit = this.creditAssociation?.creditLimit??'-'
          if (pricingDate && this.creditAssociation.pricingTier) {
            this.getTodayPricing(pricingDate, this.creditAssociation.pricingTier,)
          }
        }
      }
    },
    setForVictoryFeats(){
      if (this.isVictoryFeatsAdhocPO && this.currentView === ORDER_VIEW.CREATE_ORDER && this.defaultVictorySGSysProductId) {
        if (this.orderForm.purchaseProducts?.length > 0) {
          this.orderForm.purchaseProducts.forEach(product => {
            product.sysProductId= this.defaultVictorySGSysProductId
          })
        }
      }
      if (this.isVictoryFeatsCusPO && this.currentView === ORDER_VIEW.CREATE_ORDER  ) {
        const _datetime = new Date(new Date());
        this.orderForm.orderDate = _datetime;
      }

    },
    handleBuyerSellerNameSelect (buyerSellerId, field) {
      if (this.isOnline) {
        if (field === 'BUYER') {
          const personInCharge = this.buyerCustomerList.find(d => d.id === buyerSellerId)
          if (personInCharge) {
            this.orderForm.buyerEmail = personInCharge.email
          }
        } else {
          const personInCharge = this.sellerCustomerList.find(d => d.id === buyerSellerId)
          if (personInCharge) {
            this.orderForm.sellerEmail = personInCharge.email
          }
        }
      }
    },
    getUserListByCompanyId (companyId, isOnline) {
      if (isOnline) {
        return new Promise((resolve, reject) => {
          this.$request.get({
            url: `${this.$apis.sharedUserBaseUrl}?pageNumber=1&pageSize=99999&organizationId=${companyId}`,
          }).then(res => {
            if (res?.code === 1000) {
              const userList = res?.data?.records || []
              resolve(userList)
            }
          })
        })
      } else {
        return new Promise((resolve, reject) => {
          // additional check for offline order - no credit association
          if (this.isOnline) {
            this.$request.get({
              url: `${this.$apis.offlineOrganizationContact}?filter=sysOfflineOrganizationId : '${companyId}'&pageNumber=0&pageSize=9999`
            }).then(res => {
              if (res?.code === 1000) {
                const contactList = res.data.content
                resolve(contactList)
              }
            })
          } else {
            resolve([])
          }
        })
      }
    },
    getCompanies () {
      return new Promise(resolve => {
        this.$request.get({
          url: `${this.$apis.sharedCompanyBaseUrl}?pageNumber=1&pageSize=9999`
        }).then(res => {
          if (res?.code === 1000) {
            const companyList = res.data?.records ?? []
            resolve(companyList)
          }
        })
      })
    },
    getOrderFormData (isDraft) {
      let data = null
      let salesAndPurchaseData = null
      const{orderType,orderDate,nominationDate,pricingDate,buyerReferenceNo,sellerReferenceNo,sellerId,buyerId,sellerEmail,buyerEmail,thirdParties,buyerSysOrganizationId,sellerSysOrganizationId,port,termsAndConditions,} = this.orderForm
      // for victory feats in the order   --start
      const{territory,bunkerBarge,bunkerBargeName,bargeShipId,nextPort,bunkerLocation} = this.orderForm
      // for victory feats in the order   --end
      if (this.tradeType === ORDER_TRADE_TYPE.SALES || this.tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) {
        data = {
          salesOrder: {
            isDraft: isDraft,
            buyerId,
            buyerEmail,
            buyerSysOrganizationId,
            orderType,
            orderDate,
            nominationDate,
            pricingDate,
            sellerReferenceNo,
            thirdParties,
            port,
            termsAndConditions,

            paymentTerm: this.orderForm.buyerPaymentTerm,
            creditDay: this.orderForm.buyerCreditDay,
            paymentTermDate: this.orderForm.buyerPaymentTermDate,
            greenlightSupply: this.isBuyerGreenlightSupply,
            sellerSysOrganizationId: this.myCompany.id,
            sellerId: this.orderForm.name,
            sellerEmail: this.$store.state.user.email,
            buyerReferenceNo: this.currentView === ORDER_VIEW.EDIT_ORDER ? buyerReferenceNo : null,
            products: this.orderForm.salesProducts,
            sellerRemark: this.orderForm.remark,
            buyerRemark: this.currentView === ORDER_VIEW.EDIT_ORDER ? this.orderForm.buyerRemark : null,
            stemStartDate: this.orderForm.dateRange[0],
            stemEndDate: this.orderForm.dateRange[1],

            // for victory feats in the order --start
            territory,
            bunkerLocation:this.orderForm?.locationLevel.length==2 ?  this.orderForm?.locationLevel[1] :bunkerLocation,
            bunkerBarge,
            bunkerBargeName,
            bargeShipId,
            nextPort,
            isVictoryCust: this.isShowVictoryFeats,
            locationLevel: this.orderForm?.locationLevel

            // for victory feats in the order --end
          }
        }
        if (this.orderForm.salesOtherCosts) {
          data.salesOrder.otherCosts = this.getOtherCostList(this.orderForm.salesProducts, this.orderForm.salesOtherCosts)
        }
        if (this.orderForm.orderType === this.ORDER_TYPE.DELIVERED || this.orderForm.orderType === this.ORDER_TYPE.BARGING_NOMINATION) {
          data.salesOrder.vesselName = this.orderForm.vesselName
          data.salesOrder.vesselImoNo = this.orderForm?.vesselImoNo
          data.salesOrder.vesselShipId = this.orderForm.vesselShipId
          data.salesOrder.vesselLicenseNo = this.orderForm?.vesselLicenseNo
        } else {
          data.salesOrder.terminal = this.orderForm.terminal
          data.salesOrder.lifting = this.orderForm.lifting
        }
        if (this.linkedSalesTermContract) {
          data.salesOrder.termContractId = this.linkedSalesTermContract.id
        }
        if (this.currentView === ORDER_VIEW.EDIT_ORDER) {
          data = data.salesOrder
          data.id = this.orderForm.id
        }else{
          data.salesOrder.isVictoryCust = this.isShowVictoryFeats
        }
        if (this.tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) {
          salesAndPurchaseData = data
        }

      }
      if (this.tradeType === ORDER_TRADE_TYPE.PURCHASE || this.tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) {
        data = {
          purchaseOrder: {
            isDraft: isDraft,
            sellerId,
            sellerEmail,
            sellerSysOrganizationId,
            orderType,
            orderDate,
            nominationDate,
            pricingDate,
            buyerReferenceNo,
            thirdParties,
            port,
            termsAndConditions,
            paymentTerm: this.orderForm.sellerPaymentTerm,
            creditDay: this.orderForm.sellerCreditDay,
            paymentTermDate: this.orderForm.sellerPaymentTermDate,
            greenlightSupply: this.isSellerGreenlightSupply,
            sellerReferenceNo: this.currentView === ORDER_VIEW.EDIT_ORDER ?sellerReferenceNo : null,
            buyerSysOrganizationId: this.myCompany.id,
            buyerId: this.orderForm.name,
            buyerEmail: this.$store.state.user.email,
            products: this.orderForm.purchaseProducts,
            buyerRemark: this.orderForm.remark,
            sellerRemark: this.currentView === ORDER_VIEW.EDIT_ORDER ? this.orderForm.sellerRemark : null,
            stemStartDate: this.orderForm.dateRange[0],
            stemEndDate: this.orderForm.dateRange[1],

            // for victory feats in the order
            territory,
            bunkerLocation:this.orderForm?.locationLevel?.length==2 ?  this.orderForm?.locationLevel[1]  : bunkerLocation,

            bunkerBarge,
            bunkerBargeName,
            bargeShipId,
            nextPort,
            isVictoryCust: this.isShowVictoryFeats,
            locationLevel: this.orderForm?.locationLevel,
            supplyType: this.orderForm?.supplyType,
            // remark: this.orderForm.remark,
            // for victory feats in the order --end
          }
        }
        if (this.orderForm.purchaseOtherCosts) {
          data.purchaseOrder.otherCosts = this.getOtherCostList(this.orderForm.purchaseProducts, this.orderForm.purchaseOtherCosts)
          // data.purchaseOrder.otherCosts = this.orderForm.purchaseOtherCosts
        }
        if (this.orderForm.orderType === this.ORDER_TYPE.DELIVERED || this.orderForm.orderType === this.ORDER_TYPE.BARGING_NOMINATION) {
          data.purchaseOrder.vesselName = this.orderForm.vesselName
          data.purchaseOrder.vesselImoNo = this.orderForm?.vesselImoNo
          data.purchaseOrder.vesselShipId = this.orderForm.vesselShipId
          data.purchaseOrder.vesselLicenseNo = this.orderForm?.vesselLicenseNo
        } else {
          data.purchaseOrder.terminal = this.orderForm.terminal
          data.purchaseOrder.lifting = this.orderForm.lifting
        }
        if (this.linkedPurchaseTermContract) {
          data.purchaseOrder.termContractId = this.linkedPurchaseTermContract.id
        }
        if (this.currentView === ORDER_VIEW.EDIT_ORDER) {
          data = data.purchaseOrder
          data.id = this.orderForm.id
        }else{
          data.isVictoryCust = this.isShowVictoryFeats
        }
        if (this.tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) {
          salesAndPurchaseData.purchaseOrder = data.purchaseOrder
          return salesAndPurchaseData
        }
      }

      return data
    },
    submitOrderComm () {
      this.$refs.orderCommission.$refs.orderCommissionForm.validate(valid => {
        if (valid) {
          this.handleUpdateOrderComm()
        } else {
          this.$message.error('Error. Required fields not filled in or Some field has error.')
          this.isOrderFormLoading = false
        }
      })
    },
    handleUpdateOrderComm () {
      this.isOrderFormLoading = true
      const data = this.$refs.orderCommission.orderCommissionForm
      updateOrderComm(this.orderId, data).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Commission has been updated.')
          this.handleOrderViewChange('VIEW_ORDER')
          this.populateCommissionForm(res.data)
          this.isOrderFormLoading = false
        } else {
          this.isOrderFormLoading = false
        }
      }).catch(e => { this.isOrderFormLoading = false })
    },
    handleSubmitOrder (isDraft) {
      const isCreateOrder = !((this.currentView === ORDER_VIEW.EDIT_ORDER && (this.tradeType === ORDER_TRADE_TYPE.SALES ? this.orderForm.sellerOrderStatus === ORDER_STATUS.DRAFT : this.orderForm.buyerOrderStatus === ORDER_STATUS.DRAFT)) || this.currentView === ORDER_VIEW.EDIT_ORDER)

      if (this.isEditPurchaseOrder && !this.isVictoryFeatsCusPO) {

        this.$refs.orderForm.validate(valid => {
          if (valid) {
            const data = []
            this.orderForm.products.forEach(p => {
              data.push({
                id: p.id,
                finalQuantity: p.finalQuantity,
                pricingDate: this.orderForm.pricingDate
              })
              p.sysProductSpecificationId ??= this.defaultSysProductSpecId
              p.minimumQuantity ??= 0.01
              p.maximumQuantity ??= p.finalQuantity
            })
            this.handleUpdateOrderProductFinalQty(data)
          } else {
            this.$message.error('Error. Required fields not filled in or Some field has error.')
          }
        })
      } else {
         // victory features validate
        if(this.isVictoryFeatsCusPO) {
          let hasError = false
          this.orderForm.purchaseProducts.map(item => {
            if(!item.price || (item.price <= 0)) {
              this.showTierPriceError();
              hasError = true
              return;
            }
          })
          if(hasError) {
            return;
          }
        }
        if ((this.tradeType === ORDER_TRADE_TYPE.PURCHASE || this.tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES ) && this.currentView === ORDER_VIEW.CREATE_ORDER) {
          this.orderForm.purchaseProducts.forEach(p => {
            if (p.priceType === ORDER_PRICE_TYPE.FORMULA) {
              this.$refs.purchaseProductTable.toggleRowExpansion(p, true)
            }
            p.sysProductSpecificationId ??= this.defaultSysProductSpecId
            p.minimumQuantity ??= 0.01
            p.maximumQuantity ??= p.finalQuantity
          })
        }
        if (this.tradeType === ORDER_TRADE_TYPE.SALES || this.tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) {
          this.orderForm.salesProducts.forEach(p => {
            if (p.priceType === ORDER_PRICE_TYPE.FORMULA) {
              this.$refs.salesProductTable.toggleRowExpansion(p, true)
            }
            p.sysProductSpecificationId ??= this.defaultSysProductSpecId
            p.minimumQuantity ??= 0.01
            p.maximumQuantity ??= p.finalQuantity
          })
        }


        this.$nextTick(() => {
          this.$refs.orderForm.validate((valid,obj) => {
            if (valid) {
              // debugger
              const order = this.getOrderFormData(isDraft)
              let salesOrder = null
              if (isCreateOrder) {
                salesOrder = order.salesOrder
              } else {
                if (order.sellerSysOrganizationId === this.myCompany.id) {
                  salesOrder = order
                }
              }
              if (salesOrder && !isDraft) {
                this.handleVerifyOrderCA(salesOrder).then(val => {
                  if (val) {
                    if (this.currentView === ORDER_VIEW.EDIT_ORDER) {
                      order.creditAuthorizationRequestRemark = val
                    } else {
                      order.salesOrder.creditAuthorizationRequestRemark = val
                    }
                  }
                  isCreateOrder ? this.handleCreateOrder(order, isDraft) : this.handleUpdateOrder(order, isDraft)

                })
              } else {
                if (this.isEditPurchaseOrder && this.isVictoryFeatsCusPO) {
                  const data = order.purchaseOrder
                  data.id = this.orderForm.id
                    this.handleUpdateOrder(data, isDraft)
                  } else {
                    isCreateOrder ? this.handleCreateOrder(order, isDraft) : this.handleUpdateOrder(order, isDraft)
                  }

              }
            } else {
              this.$message.error('Error. Required fields not filled in or Some field has error.')
              this.isOrderFormLoading = false
            }
          })
        })
      }
    },
    handleCreateOrder (order, isDraft) {

      this.isOrderFormLoading = true
      createOrder(order).then(res => {
        if (res?.code === 1000) {
          isDraft ? this.$message.success('Draft has been created.') : this.$message.success('Order has been created.')
          let order = null
          if (this.tradeType === ORDER_TRADE_TYPE.PURCHASE_AND_SALES) {
            this.handleCloseOrderForm()
          } else {
            if (this.tradeType === ORDER_TRADE_TYPE.SALES) {
              order = res.data.salesOrder
            } else {
              order = res.data.purchaseOrder
            }
            this.$emit('handleOrderIdChange', order.id)
            this.handleOrderViewChange('VIEW_ORDER')
            this.populateOrderForm(order)
          }
        } else {
          this.isOrderFormLoading = false
        }
      }).catch(e => { this.isOrderFormLoading = false })
    },
    handleVerifyOrderCA (order) {
      return new Promise((resolve, reject) => {
        verifyOrderCA(order).then(res => {
          if (res?.code === 1000) {
            if (res.data) {
              const title = 'Confirm Submission?'
              const body = 'Credit Authorisation is required. Please enter a reason.'
              const validateCAField = (input) => {
                if (input == null || input.trim() == ''||  input.length < 1) {
                  return 'This field is required.'
                } else {
                  if (input?.length > 500) {
                    return 'Character limit of 500 is exceeded.'
                  }
                  return true
                }
              }
              this.$prompt(body, title, {
                confirmButtonText: this.$t('submit'),
                cancelButtonText: this.$t('cancel'),
                type: 'warning',
                inputType: 'textarea',
                inputValidator: validateCAField
              }).then(({ value }) => {
                resolve(value)
              }).catch(() => {})
            } else {
              resolve(false)
            }
          }
        }).catch(e => {
          reject(e)
        })
      })
    },
    handleUpdateOrderProductFinalQty (data) {

      this.isOrderFormLoading = true
      updateOrderProductFinalQty(this.orderId, data).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Order has been updated.')
          this.isEditPurchaseOrder = false
          this.populateOrderForm(res.data)
        } else {
          this.isOrderFormLoading = false
        }
      }).catch(e => { this.isOrderFormLoading = false })
    },
    handleUpdateOrder (order, isDraft) {
      this.isOrderFormLoading = true
      updateOrder(order).then(res => {
        if (res?.code === 1000) {
          isDraft ? this.$message.success('Draft has been updated.') : this.$message.success('Order has been updated.')
          this.handleOrderViewChange('VIEW_ORDER')
          this.populateOrderForm(res.data)
        } else {
          this.isOrderFormLoading = false
        }
      }).catch(e => { this.isOrderFormLoading = false })
    },
    handleDeleteOrder () {
      this.isOrderFormLoading = true
      deleteOrder(this.orderId)
        .then(res => {
          if (res?.code === 1000) {
            this.$message.success('Order has been deleted.')
            this.handleCloseOrderForm()
          } else {
            this.isOrderFormLoading = false
          }
        })
        .catch(e => { this.isOrderFormLoading = false })
    },
    handleConfirmOrderVerifyCA () {
      if (this.tradeType === ORDER_TRADE_TYPE.SALES) {
        const order = this.getOrderFormData(false).salesOrder
        this.handleVerifyOrderCA(order).then(val => {
          this.handleConfirmOrder(val)
        })
      } else {
        this.handleConfirmOrder()
      }
    },
    handleConfirmOrder (val) {
      this.isOrderFormLoading = true
      const data = {}
      if (val) {
        data.creditAuthorizationRequestRemark = val
      }
      confirmOrder(this.orderId, data).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Order has been confirmed.')
          this.populateOrderForm(res.data)
        } else {
          this.isOrderFormLoading = false
        }
      }).catch(e => { this.isOrderFormLoading = false })
    },
    handleCancelOrder () {
      this.isOrderFormLoading = true
      cancelOrder(this.orderId).then(res => {
        if (res?.code === 1000) {
          isOrderCancellable(this.orderId).then(res => {
            this.isOrderCancellable = res.data
          })
          this.$message.success('Order has been cancelled.')
          this.populateOrderForm(res.data)
        } else {
          this.isOrderFormLoading = false
        }
      }).catch(e => { this.isOrderFormLoading = false })
    },
    handleGenerateOrderPdf () {
      generateOrderPdf(this.orderId).then(res => {
        const blob = new Blob([res], { type: 'application/pdf' })
        const url = URL.createObjectURL(blob)
        window.open(url)

        // revokeObjectURL after 20min
        setTimeout(() => {
          URL.revokeObjectURL(url)
        }, 1200000)
      }).catch(e => {})
    },
    handleVerifyOrder (orderId, products, otherCosts) {
      const productData = this.$_.cloneDeep(products)
      productData.forEach(p => {
        p.jobIds = p.jobInfo.selectedJobs.map(job => job.jobId)
        p.supplyDate = new Date(p.supplyDate)
        p.priceStartDate = new Date(p.priceStartDate)
        p.priceEndDate = new Date(p.priceEndDate)
        if (!p.pricePremium) {
          p.pricePremium = 0
        }
      })
      const data = {
        orderId: orderId,
        products: productData,
        otherCosts: otherCosts
      }
      verifyOrder(data).then(res => {
        if (res?.code === 1000) {
          this.$message.success('Order items have been verified.')
          this.dialogVerifyOrder = false
          getOrderById(this.orderId).then(res => {
            if (res?.code === 1000) {
              const isAllVerified = res.data.products.findIndex(p => p.status === 'PENDING') === -1 && res.data.otherCosts.findIndex(oc => oc.status === 'CONFIRMED') === -1
              this.$emit('handleIsAllVerifiedChange', isAllVerified)
              this.$emit('handleOrderViewChange', 'VIEW_ORDER')
              this.populateOrderForm(res.data)
            }
          }).catch(e => {})
        }
      }).catch(e => {})
    },
  populateDeliveredTable () {
      this.getJobInfoByOrderId().then(val => {
        this.deliveredProductTableData = val || []
        this.isDeliveredProductTableLoading = false
      }
      )
    },
    getJobInfoByOrderId () {
      return new Promise((resolve, reject) => {
        this.$request.get({
          url: `${this.$apis.getJobInfoByOrder}?orderId=${this.orderId}`
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data)
          }
        })
      })
    },
    async handleSearchMpaReceivingVessels (searchKey) {
      getReceivingVesselListBySearch(searchKey).then(res => {
        if (res?.code === 1000) {
          this.searchedVesselList = res.data.records?.length > 0 ? res.data.records : []
        }
      }).catch (e => {
      }).finally (() => {})
    },
    searchVesselvisibleChange (isVisit) {
      if (isVisit) {
        this.handleSearchMpaReceivingVessels('')
      }
    },
    handleSelectVessel (selectionId) {
      const selection = this.searchedVesselList.find(v => v.id == selectionId)
      this.orderForm.vesselShipId = selection?.id
      this.orderForm.vesselLicenseNo = selection?.licenceNo
      this.orderForm.vesselName = selection?.shipName
      this.orderForm.vesselImoNo = selection?.imo
      this.setNextPort(selection?.supplyType)
    },
    setNextPort( supplyType ) {
      if(this.isVictoryFeatsCusPO) {
        this.nextPortDisabled = supplyType === "D"
        supplyType = supplyType ?? "D"
        this.orderForm.supplyType = supplyType
        this.orderForm.nextPort = supplyType === "D" ? "Singapore" : ""
      }
    },
    handleInputNextPort(val) {
      const singapore = ['singapore', 'sg', 'sgp']
      this.orderForm.supplyType = singapore.includes(val.trim().toLowerCase()) ? "D" : "I"
    },
    handleChangeBarge(selectedBargeId) {

     if (selectedBargeId) {
        this.orderForm.bargeShipId = selectedBargeId;
        const barges = this.availableBargesOptions ?? [];
        const shipList = this.isVictoryFeatsSelfCollectKiosk?this.selfCollectKioskShips:this.availableBargesOptions
        const shipItem = barges
          ? shipList.find((ship) => ship?.id === selectedBargeId)
          : {};
        this.orderForm.bunkerBargeName = shipItem?.shipName;
        this.orderForm.bunkerBarge = shipItem?.imo;

      }
    },
    reqCheckAvailableBargesOptions(parmas) {

      getAvailableBarges(parmas)
        .then((res) => {
          this.availableBargesLoading = false;
          if (res?.code === 1000) {
            this.availableBargesOptions = sortByAlphabetical(
              res.data || [],
              "shipName"
            ).filter(
              (ship) =>
                !ship.charterId ||
                (ship.charterId && ship.charterId === this.currentCompany.id)
            );
          }
        })
        .catch((err) => (this.availableBargesLoading = false));
    },
    visibleChange(isVisit) {

      if (!isVisit) return;
        /*
          only Barging spot order (COA TC) check by termContractId
        */
        const termContractId = this?.orderForm?.termContract?.id;
        const termContractType = this?.orderForm?.termContract?.termContractType;
        const startDate = this.orderForm?.orderDate;
        const endDate = this.orderForm?.orderDate
          if (
            [TERM_CONTRACT_TYPE.TC, TERM_CONTRACT_TYPE.COA].includes(termContractType) &&
            termContractId
          ) {
            this.reqCheckAvailableBargesOptions({ termContractId, startDate, endDate });
          } else {
            const operatorSysOrganizationId = (this.tradeType == this.ORDER_TRADE_TYPE.PURCHASE ?
            this?.orderForm?.sellerSysOrganizationId: this.currentCompany.id )
            if (!operatorSysOrganizationId || operatorSysOrganizationId?.trim()!=='') this.availableBargesOptions =[]
            this.reqCheckAvailableBargesOptions({
                operatorSysOrganizationId,
                startDate,
                endDate,
            });
          }

    },
    handleChangeOrderDateViaSummary(val){
      this.orderForm.nominationDate??=this.orderForm.orderDate
      this.orderForm.stemStartDate??=this.orderForm.nominationDate
      this.orderForm.stemEndDate??=this.orderForm.nominationDate
      if (!this.orderForm.dateRange) {
        this.orderForm.dateRange=[this.orderForm.stemStartDate,this.orderForm.stemEndDate]
      }
    },
    getTodayPricing(data, pricingTierId){
      if ( data && pricingTierId) {
        const _date = this.$moment(this.orderForm.pricingDate).format('YYYY-MM-DD')
        const orgId = this.sellerHasVictoryFeats ? this.creditAssociation.counterpartySysOrganizationId: this.currentCompany.id
        this.pricingTierDaily( _date,orgId).then( res => {
          const data = res?.find(item => item.tierId == pricingTierId)
          if (res && data) {
            if (data.tierName=='STD') {
              this.todayPricing = data.stdAmount
            }else{
              const marketPrice = data.marketPrice ? parseFloat(data.marketPrice):0
              const exchangeRate = data.exchangeRate ? parseFloat(data.exchangeRate):0
              const addMt = data.addMt? parseFloat(data.addMt):0
              const todayPricing = this.calculatePricingTier(marketPrice, exchangeRate, addMt)
              this.todayPricing =todayPricing;
            }
          } else {
            this.todayPricing = "-"
          }
          this.setSupplierProductPrice()
        })
      } else {
        this.todayPricing = "-"
        this.setSupplierProductPrice()
      }
    },
    getBindedLoactionAndBarge () {
      return new Promise((resolve, reject) => {
        const url = `${this.$apis.bindedBargeAndLoaction}?orgId=${this.tradeType ==ORDER_TRADE_TYPE.PURCHASE?  this.orderForm.sellerSysOrganizationId : this.currentCompany.id}`
        this.$request.get({
          url,
        }).then(res => {
          if (res?.code === 1000) {
            resolve(res.data )
          }
        }).catch(e => {
          reject(e)
        })
      })
    },
    handleLocationOption(isVisit){
      if (!isVisit) return;
      if (this.isVictoryFeatsSelfCollectKiosk) {
        this.getBindedLoactionAndBarge().then(data=>{
          if (data?.shipLocationMapping!=null && data?.locations?.length > 0) {
            const shipLocationMapping =  convertMapKeyValue(data.shipLocationMapping);
            this.selfCollectKioskLocationOptions = data.locations.map(item=>({
              value:item,
              label:item,
              shipId:shipLocationMapping[item]
            }));
          } else {
            this.selfCollectKioskLocationOptions = []
          }
          this.selfCollectKioskShips = data?.ships ?? []
        })

      } else {
        this.locationOptions = this.locationList
      }

    },
    handleChangeCollectKioskLocation(value){
      if (!value) return;
      this.orderForm.bunkerLocation=value
      this.getLocationLevel(value)
      const item = this.selfCollectKioskLocationOptions.find(item=>item.value == value)
      if (!!item?.shipId) {
        this.handleChangeBarge(item.shipId)
      } else {
        this.$message.error('Get bunker barge failed!')
      }
    },
    clearLocationAndBunkerBarge(){
      this.orderForm.bunkerBargeName =null
      this.orderForm.bunkerBarge =null
      this.orderForm.bargeShipId =null
      this.orderForm.bunkerLocation =null
      this.orderForm.locationLevel =[]

    },
    setSupplierProductPrice() {
      if(this.isSeller && (this.canEditVictoryFields || this.currentView === ORDER_VIEW.CREATE_ORDER )&& this.orderForm.orderType === this.ORDER_TYPE.DELIVERED && this.tradeType === this.ORDER_TRADE_TYPE.PURCHASE) {
        let amount = 0
        if( this.orderForm.sellerSysOrganizationId ) {
          amount = isNaN(this.todayPricing) ? 0 : this.todayPricing
        }
        this.orderForm.purchaseProducts.map(item => item.price = amount)
      }
    },
    showTierPriceError() {
      this.$message.error('Pricing No Found. Please inform the seller to update the price before creating the order.')
    }

  },
  watch: {
    "currentCompany.id": {
      handler(value) {
        if (value) {
          setTimeout(() => {
            this.$store.dispatch("getCompanySettings", value);
          }, 1000);
        }
      },
      immediate: true,
    },
  },


}
</script>
<style lang="scss" scoped>
  @import "../index";
  .search-vessel-suffix-hidden {
    /deep/.el-input__suffix {
      visibility: hidden!important;
    }
  }
  .el-collapse {
    border-top: none !important;
    border-bottom: none !important;
    /deep/.el-collapse-item__header{
      font-family: 'Roboto';
      font-style: normal;
      font-weight: 500;
      font-size: 16px;
      line-height: 20px;
      color: $second-color-font !important;
    }
  }
  .pricing-date-picker{
    /deep/.el-input__inner{
      border: none !important;
      font-size: 14px !important;
    }
  }
  .div-form-main-container{
    height: calc(100% - 60px);
  }
.nominatedQty {
  /deep/.el-input-number .el-input__inner {
    text-align: left;
  }
}
</style>
