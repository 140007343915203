var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "div",
      {
        staticClass: "search-vessel-suffix-hidden",
        class: {
          "div-frac-13-avg": !_vm.isMobileScreen,
          "div-frac-1": _vm.isMobileScreen,
        },
      },
      [
        !_vm.isVesselTBC
          ? _c(
              "el-form-item",
              { attrs: { label: "Search Vessel 111" } },
              [
                _c(
                  "el-select",
                  {
                    staticStyle: { width: "100%" },
                    attrs: {
                      "filter-method": (val) =>
                        _vm.$emit("handleSearchMpaReceivingVessels", val),
                      filterable: "",
                      "popper-class": "custom-ship-selector",
                      disabled: _vm.currentView === _vm.ORDER_VIEW.VIEW_ORDER,
                      placeholder: "Select",
                    },
                    on: {
                      "visible-change": function ($event) {
                        return _vm.$emit("searchVesselvisibleChange", $event)
                      },
                      change: function ($event) {
                        return _vm.$emit("handleSelectVessel", $event)
                      },
                    },
                    model: {
                      value: _vm.searchKey,
                      callback: function ($$v) {
                        _vm.searchKey = $$v
                      },
                      expression: "searchKey",
                    },
                  },
                  [
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          width: "200px",
                          "font-size": "14px",
                          "font-weight": "500",
                          "margin-left": "20px",
                          "margin-bottom": "10px",
                        },
                      },
                      [_vm._v("Name")]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          width: "100px",
                          "font-size": "14px",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v("IMO")]
                    ),
                    _c(
                      "span",
                      {
                        staticStyle: {
                          display: "inline-block",
                          "font-size": "14px",
                          "font-weight": "500",
                        },
                      },
                      [_vm._v("Licence")]
                    ),
                    _vm._l(_vm.searchedVesselList, function (item) {
                      return _c(
                        "el-option",
                        {
                          key: item.id,
                          attrs: { label: item.shipName, value: item.id },
                        },
                        [
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                width: "200px",
                                "font-size": "12px",
                              },
                            },
                            [_vm._v(_vm._s(item.shipName) + " ")]
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                width: "100px",
                                "font-size": "12px",
                              },
                            },
                            [_vm._v(_vm._s(item.imo))]
                          ),
                          _c(
                            "span",
                            {
                              staticStyle: {
                                display: "inline-block",
                                "font-size": "12px",
                              },
                            },
                            [_vm._v(_vm._s(item.licenceNo))]
                          ),
                        ]
                      )
                    }),
                  ],
                  2
                ),
              ],
              1
            )
          : _vm._e(),
      ],
      1
    ),
    _c(
      "div",
      {
        class: {
          "div-frac-13-avg": !_vm.isMobileScreen,
          "div-frac-1": _vm.isMobileScreen,
        },
      },
      [
        _c(
          "el-form-item",
          {
            attrs: {
              label: "Vessel Name",
              prop: "vesselName",
              rules: !_vm.isVesselTBC ? _vm.rules.reqRule : _vm.notReqRule,
            },
          },
          [
            _c("el-input", {
              attrs: {
                disabled:
                  _vm.currentView === _vm.ORDER_VIEW.VIEW_ORDER ||
                  _vm.isVesselTBC,
              },
              model: {
                value: _vm.orderForm.vesselName,
                callback: function ($$v) {
                  _vm.$set(_vm.orderForm, "vesselName", $$v)
                },
                expression: "orderForm.vesselName",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            attrs: {
              label: "IMO No.",
              prop: "vesselImoNo",
              rules: _vm.rules.vesselImoNo,
            },
          },
          [
            _c("el-input", {
              attrs: {
                disabled:
                  _vm.currentView === _vm.ORDER_VIEW.VIEW_ORDER ||
                  _vm.isVesselTBC,
              },
              model: {
                value: _vm.orderForm.vesselImoNo,
                callback: function ($$v) {
                  _vm.$set(_vm.orderForm, "vesselImoNo", $$v)
                },
                expression: "orderForm.vesselImoNo",
              },
            }),
          ],
          1
        ),
        _c(
          "el-form-item",
          {
            attrs: {
              label: "License No.",
              prop: "vesselLicenseNo",
              rules: _vm.LICENSE_NUMBKER_RULE,
            },
          },
          [
            _c("el-input", {
              attrs: {
                disabled:
                  _vm.currentView === _vm.ORDER_VIEW.VIEW_ORDER ||
                  _vm.isVesselTBC,
              },
              model: {
                value: _vm.orderForm.vesselLicenseNo,
                callback: function ($$v) {
                  _vm.$set(_vm.orderForm, "vesselLicenseNo", $$v)
                },
                expression: "orderForm.vesselLicenseNo",
              },
            }),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }